export default {
    "登录失效，请重新登录": "登录失效，请重新登录",
    "代购中心": "代购中心",
    "订单列表": "订单列表",
    "代购售后管理": "代购售后管理",
    "地址管理": "地址管理",
    "我的收藏": "我的收藏",
    "申请成为采购商": "申请成为采购商",
    "采购商服务中心": "采购商服务中心",
    "采购列表": "采购列表",
    "采购售后管理": "采购售后管理",
    "采购服务规则": "采购服务规则",
    "采购入账记录": "采购入账记录",
    "个人主页": "个人主页",
    "财务管理": "财务管理",
    "余额充值": "余额充值",
    "银行卡列表": "银行卡列表",
    "提现地址列表": "提现地址列表",
    "提现": "提现",
    "消费记录": "消费记录",
    "同步订单管理": "同步订单管理",
    "创建appKey": "创建appKey",
    "更新回调地址": "更新回调地址",
    "解决方案": "解决方案",
    "招募供应商": "招募供应商",
    "登录": "登录",
    "店铺开通": "店铺开通",
    "公司电话": "公司电话",
    "15038350530": "15038350530",
    "公司邮箱": "公司邮箱",
    "97668216@qq.com": "97668216@qq.com",
    "公司地址": "公司地址",
    "河南省郑州市金水区花园路正弘中心1417": "河南省郑州市金水区花园路正弘中心1417",
    "联系我们": "联系我们",
    "微信号": "微信号",
    "加入我们": "加入我们",
    "关于我们": "关于我们",
    "隐私政策": "隐私政策",
    "帮助中心": "帮助中心",
    "请输入电子邮件": "请输入电子邮件",
    "请输入登陆密码": "请输入登陆密码",
    "电子邮件": "电子邮件",
    "登录密码": "登录密码",
    "忘记密码": "忘记密码",
    "提交": "提交",
    "还没有账号": "还没有账号",
    "注册": "注册",
    "全球贸易平台！": "全球贸易平台！",
    "使用taoify建立您的在线业务在全球任何地方销售您的产品": "使用taoify建立您的在线业务在全球任何地方销售您的产品",
    "中国·郑州·总部": "中国·郑州·总部",
    "售前手机": "售前手机",
    "合作邮箱": "合作邮箱",
    "郑州全搜索计算机技术服务有限公司": "郑州全搜索计算机技术服务有限公司",
    "跨境行业全链路解决方案软件服务商": "跨境行业全链路解决方案软件服务商",
    "郑州全搜索计算机技术服务有限公司（www.zzqss.com）（简称郑州全搜索）是一家以互联网信息技术开发和物联网IOT开发和服务为核心，为企业提供现代的网络服务公司。": "郑州全搜索计算机技术服务有限公司（www.zzqss.com）（简称郑州全搜索）是一家以互联网信息技术开发和物联网IOT开发和服务为核心，为企业提供现代的网络服务公司。",
    "郑州全搜索计算机技术服务有限公司依托自身完善的服务体系，强大的技术支持力量，以及丰富的经验、信息资源和市场运作实力，使公司得到迅猛发展，并拥有一支富有创造力、充满激情、团结向上的队伍。": "郑州全搜索计算机技术服务有限公司依托自身完善的服务体系，强大的技术支持力量，以及丰富的经验、信息资源和市场运作实力，使公司得到迅猛发展，并拥有一支富有创造力、充满激情、团结向上的队伍。",
    "郑州全搜索设立专门的产品研发团队，探索国际领先的互联网点上模式，扑捉未来市场的需求，将其转化为自己的产品，与互联网各领域内从业者与专家保持密切沟通，形成全搜索独特的市场敏锐和前瞻性，开发出多套国内领先国际上普遍使用的跨境电商系统。": "郑州全搜索设立专门的产品研发团队，探索国际领先的互联网点上模式，扑捉未来市场的需求，将其转化为自己的产品，与互联网各领域内从业者与专家保持密切沟通，形成全搜索独特的市场敏锐和前瞻性，开发出多套国内领先国际上普遍使用的跨境电商系统。",
    "公交站：花园路农科路": "公交站：花园路农科路",
    "地铁站：花园路农科路": "地铁站：花园路农科路",
    "在这里开启你的全球生意": "在这里开启你的全球生意",
    "您的想法，taoify为您实现": "您的想法，taoify为您实现",
    "快速搭建你的在线商店": "快速搭建你的在线商店",
    "是一款免费的易于使用的跨境在线商店系统，可以将您的商品销往全世界。": "是一款免费的易于使用的跨境在线商店系统，可以将您的商品销往全世界。",
    "taoify在线商店支持全球各种语言，全球各种货币。": "taoify在线商店支持全球各种语言，全球各种货币。",
    "taoify在线商店五大优势": "taoify在线商店五大优势",
    "完全免费": "完全免费",
    "taoify店铺完全免费使用，无月租，无提成，无手续费": "taoify店铺完全免费使用，无月租，无提成，无手续费",
    "完美适配移动端": "完美适配移动端",
    "专为移动端定制模板，用户体验更好": "专为移动端定制模板，用户体验更好",
    "全球本地收款": "全球本地收款",
    "支持全球本地化收款方式，多种支付渠道整合": "支持全球本地化收款方式，多种支付渠道整合",
    "一键对接全球配送": "一键对接全球配送",
    "支持全球数百家国际物流一键绑定，自动履单。服务采购质检寄送": "支持全球数百家国际物流一键绑定，自动履单。服务采购质检寄送",
    "海量商品导入": "海量商品导入",
    "支持淘宝1688官方接口几十亿商品导入，稳定快速。": "支持淘宝1688官方接口几十亿商品导入，稳定快速。",
    "帮助全球商家探索新型销售模式": "帮助全球商家探索新型销售模式",
    "零售者": "零售者",
    "适用于线上零售商": "适用于线上零售商",
    "小成本开店": "小成本开店",
    "批发商": "批发商",
    "为想做中国商品批发的": "为想做中国商品批发的",
    "创业者提供解决方案": "创业者提供解决方案",
    "商业公司": "商业公司",
    "定制专属业务模式": "定制专属业务模式",
    "在全球销售中国商品": "在全球销售中国商品",
    "物流企业": "物流企业",
    "轻松拓展业务范围": "轻松拓展业务范围",
    "中国商品销售触手可得": "中国商品销售触手可得",
    "拓展渠道难？建站成本高？引流难？销量低？无效率？无成交？": "拓展渠道难？建站成本高？引流难？销量低？无效率？无成交？",
    "taoify一站式全球跨境电商平台，洞察行业最新动态，解决供应商出海痛点": "taoify一站式全球跨境电商平台，洞察行业最新动态，解决供应商出海痛点",
    "功能展示（部分）": "功能展示（部分）",
    "建店周期": "建店周期",
    "是否有店铺成本": "是否有店铺成本",
    "店铺装修": "店铺装修",
    "店铺冻结": "店铺冻结",
    "店铺语言": "店铺语言",
    "如何选品上架": "如何选品上架",
    "选品来源": "选品来源",
    "如何收款": "如何收款",
    "如何处理订单": "如何处理订单",
    "如何发货": "如何发货",
    "如何添加文章说明": "如何添加文章说明",
    "店铺推广": "店铺推广",
    "1-2周": "1-2周",
    "有，1500$/月": "有，1500$/月",
    "自己学习配置&购买模板": "自己学习配置&购买模板",
    "会": "会",
    "多平台切换": "多平台切换",
    "支持21种语言": "支持21种语言",
    "自己选品&上架": "自己选品&上架",
    "自己找货，手动添加&借助收费应用选品": "自己找货，手动添加&借助收费应用选品",
    "需有自己的paypal账户": "需有自己的paypal账户",
    "自己采购": "自己采购",
    "自己对接物流": "自己对接物流",
    "接入shopify博客": "接入shopify博客",
    "5分钟": "5分钟",
    "无": "无",
    "免费模板&自定义模板&定制化开发&购买模板": "免费模板&自定义模板&定制化开发&购买模板",
    "不会": "不会",
    "一个平台全部搞定": "一个平台全部搞定",
    "支持所有语言": "支持所有语言",
    "taoify选品&上架": "taoify选品&上架",
    "taoify平台一键上传淘宝、1688平台商品": "taoify平台一键上传淘宝、1688平台商品",
    "可支持任何收款方式": "可支持任何收款方式",
    "自动采购": "自动采购",
    "taoify平台搞定": "taoify平台搞定",
    "taoify平台自定义编辑": "taoify平台自定义编辑",
    "建立在线商店": "建立在线商店",
    "您的商店，您来做主": "您的商店，您来做主",
    "建立在线店面": "建立在线店面",
    "只需要提交相关信息，taoify自动为您创建商店，把您想要销售中国商品的愿景变为现实，您将会是下一个大卖家": "只需要提交相关信息，taoify自动为您创建商店，把您想要销售中国商品的愿景变为现实，您将会是下一个大卖家",
    "在线创建商店": "在线创建商店",
    "打造您想要的品牌": "打造您想要的品牌",
    "由专业的设计师团队创建的精美模板,可在社区自定义装扮，打造个性小店": "由专业的设计师团队创建的精美模板,可在社区自定义装扮，打造个性小店",
    "开通金流物流": "开通金流物流",
    "使用taoify给您提供的全球金流物流渠道，轻松开通您自己的金流物流渠道。": "使用taoify给您提供的全球金流物流渠道，轻松开通您自己的金流物流渠道。",
    "在任何时间地点销售您的产品": "在任何时间地点销售您的产品",
    "使用taoify在线商店，可以分享到各大社交平台，集成taoify销售渠道，将您商店中的商品销往世界各地": "使用taoify在线商店，可以分享到各大社交平台，集成taoify销售渠道，将您商店中的商品销往世界各地",
    "开始销售": "开始销售",
    "简单的商品上传": "简单的商品上传",
    "全球最方便快捷的商品上传方式": "全球最方便快捷的商品上传方式",
    "只需要在taoify网站选择好想要销售的商品，点击就能一键上传到你的商店，销售什么商品由你来决定": "只需要在taoify网站选择好想要销售的商品，点击就能一键上传到你的商店，销售什么商品由你来决定",
    "成千上万的优质商品任您选择": "成千上万的优质商品任您选择",
    "快速可靠的支付方式": "快速可靠的支付方式",
    "提供安全快捷的支付方式": "提供安全快捷的支付方式",
    "您的顾客在选择好商品之后，可以选择taoify集成的当地的安全支付方式为您快捷收款，解决您的跨境收款难题。": "您的顾客在选择好商品之后，可以选择taoify集成的当地的安全支付方式为您快捷收款，解决您的跨境收款难题。",
    "抓住每一次生意的机会": "抓住每一次生意的机会",
    "灵活的物流渠道选择": "灵活的物流渠道选择",
    "灵活的物流渠道选择，把货物安全的交付给您的顾客": "灵活的物流渠道选择，把货物安全的交付给您的顾客",
    "你可以选择使用taoify集成的全球物流渠道，可以安全快捷的将您的货物交付给您的全球顾客。": "你可以选择使用taoify集成的全球物流渠道，可以安全快捷的将您的货物交付给您的全球顾客。",
    "给顾客提供优质的服务": "给顾客提供优质的服务",
    "内置营销工具": "内置营销工具",
    "第一次开店也能轻松完成业绩成倍增长": "第一次开店也能轻松完成业绩成倍增长",
    "使用我们的营销工具": "使用我们的营销工具",
    "接触潜在顾客": "接触潜在顾客",
    "使用社区营销、推广联盟、积分工具等寻找新的购物者": "使用社区营销、推广联盟、积分工具等寻找新的购物者",
    "与客户互动": "与客户互动",
    "自定义电子邮件营销内容，智能AI工具，随时随地和客户联系": "自定义电子邮件营销内容，智能AI工具，随时随地和客户联系",
    "数据分析": "数据分析",
    "掌握会员数据，分析受众并持续进行客户洞察，拉新留存转化": "掌握会员数据，分析受众并持续进行客户洞察，拉新留存转化",
    "履行每个订单": "履行每个订单",
    "您商店中所产生的每一个订单，都会由taoify帮您完整履单": "您商店中所产生的每一个订单，都会由taoify帮您完整履单",
    "运营监督": "运营监督",
    "taoify会跟踪付款和查看实时业务，因此您可以更专注于您的店铺优化": "taoify会跟踪付款和查看实时业务，因此您可以更专注于您的店铺优化",
    "资金管理": "资金管理",
    "为商家设置专属的资金池，一键查看您的财务信息": "为商家设置专属的资金池，一键查看您的财务信息",
    "超百万个企业家选择taoify创建业务商店": "超百万个企业家选择taoify创建业务商店",
    "万": "万",
    "全球商户数量": "全球商户数量",
    "来自国家": "来自国家",
    "占全球电子商务总量": "占全球电子商务总量",
    "亿": "亿",
    "经济活动量": "经济活动量",
    "点击免费开通": "点击免费开通",
    "无论你是谁": "无论你是谁",
    "任何人都可以轻松经营taoify商店": "任何人都可以轻松经营taoify商店",
    "taoify.shop用户隐私政策": "taoify.shop用户隐私政策",
    "taoify.shop(以下简称“我们”)非常重视对用户个人隐私和个人信息安全的保护。我们将根据现行法律和政策采取相应的管理和安全保护措施，并制定本隐私政策(以下简称“本政策”)，同时提醒您:": "taoify.shop(以下简称“我们”)非常重视对用户个人隐私和个人信息安全的保护。我们将根据现行法律和政策采取相应的管理和安全保护措施，并制定本隐私政策(以下简称“本政策”)，同时提醒您:",
    "本隐私政策适用干taoify.shop提供的产品或服务，包括您访问taoify.shop网站和/或登录相关应用程序时taoify.shop提供的服务": "本隐私政策适用干taoify.shop提供的产品或服务，包括您访问taoify.shop网站和/或登录相关应用程序时taoify.shop提供的服务",
    "请注意，本政策不适用于其他第三方提供给您的服务，此类服务适用第三方声明的隐私政策。": "请注意，本政策不适用于其他第三方提供给您的服务，此类服务适用第三方声明的隐私政策。",
    "在您使用taoify.shop产品和/或服务之前，请阅读并确认您已完全理解本政策。使用由taoify.shop提供的产品和/或服务即表明您已完全理解并同意本政策。如果您有任何问题，意见或建议，请发送电子邮件至:97668216@qq.com或致电我们的客服。": "在您使用taoify.shop产品和/或服务之前，请阅读并确认您已完全理解本政策。使用由taoify.shop提供的产品和/或服务即表明您已完全理解并同意本政策。如果您有任何问题，意见或建议，请发送电子邮件至:97668216@qq.com或致电我们的客服。",
    "一、隐私政策": "一、隐私政策",
    "本部分将带您了解:": "本部分将带您了解:",
    "taoify.shop何时会收集您的个人信息(第2节);": "taoify.shop何时会收集您的个人信息(第2节);",
    "taoify.shop收集哪些个人信息(第3-6节);": "taoify.shop收集哪些个人信息(第3-6节);",
    "taoify.shop为何使用您的个人信息，以及如何使用和保护您的个人信息(第7-12节);": "taoify.shop为何使用您的个人信息，以及如何使用和保护您的个人信息(第7-12节);",
    "撤回/更正个人信息或处理与隐私政策有关的问题(第13-15节);": "撤回/更正个人信息或处理与隐私政策有关的问题(第13-15节);",
    "taoify.shop何时会收集您的个人信息?": "taoify.shop何时会收集您的个人信息?",
    "我们将/可能在以下情况下收集您的个人信息": "我们将/可能在以下情况下收集您的个人信息",
    "当您注册为卖方和/或使用本服务或平台，或在平台上开立账户时；": "当您注册为卖方和/或使用本服务或平台，或在平台上开立账户时；",
    "当您在线或以书面形式提交表格时，包括但不限于申请表或与我们的产品或服务相关的其他表格；": "当您在线或以书面形式提交表格时，包括但不限于申请表或与我们的产品或服务相关的其他表格；",
    "当您为与我们互动而签署协议或提供其他文件或信息时，或当您使用我们的产品和服务时；": "当您为与我们互动而签署协议或提供其他文件或信息时，或当您使用我们的产品和服务时；",
    "当您通过电话(可被录音)即时通讯应用程序或软件、信件、传真、电子邮件、面对面会议、交媒体平台等方式与我们沟通时；": "当您通过电话(可被录音)即时通讯应用程序或软件、信件、传真、电子邮件、面对面会议、交媒体平台等方式与我们沟通时；",
    "当您在我们的论坛提出问题或回答其他用户的问题时；": "当您在我们的论坛提出问题或回答其他用户的问题时；",
    "当您使用我们的电子服务，或通过我们网站上的应用程序或服务与我们沟通时，包括但不限于通过cookies(当您接触我们的应用程序或网站时，我们可能会设置)进行收集;": "当您使用我们的电子服务，或通过我们网站上的应用程序或服务与我们沟通时，包括但不限于通过cookies(当您接触我们的应用程序或网站时，我们可能会设置)进行收集;",
    "当您通过我们的服务进行交易时": "当您通过我们的服务进行交易时",
    "当您向我们提供意见或投诉时": "当您向我们提供意见或投诉时",
    "当您报名参加意赛和其他营销活动时": "当您报名参加意赛和其他营销活动时",
    "当您因任何原因向我们提交个人信息时，以上只是我们可能会收集您的个人信息的一部分情况": "当您因任何原因向我们提交个人信息时，以上只是我们可能会收集您的个人信息的一部分情况",
    "taoify.shop收集哪些个人信息?": "taoify.shop收集哪些个人信息?",
    "taoify.shop收集的个人信息包括但不限于": "taoify.shop收集的个人信息包括但不限于",
    "基本信息(包括您的姓名，年龄，生日，性别，国籍、地址，账单地址，电话号码，电子邮箱地址等)": "基本信息(包括您的姓名，年龄，生日，性别，国籍、地址，账单地址，电话号码，电子邮箱地址等)",
    "身份信息(包括身份证，工作证、护照、驾驶证，其他身份证件等);": "身份信息(包括身份证，工作证、护照、驾驶证，其他身份证件等);",
    "面部特征;": "面部特征;",
    "网络身份信息(包括与上述内容相关的系统帐号，IP地址，电子邮件地址和密码):": "网络身份信息(包括与上述内容相关的系统帐号，IP地址，电子邮件地址和密码):",
    "财务信息(交易、银行账户及积分、优惠券，taoify.shop账户余额等):": "财务信息(交易、银行账户及积分、优惠券，taoify.shop账户余额等):",
    "上网记录(包括计算机IP地址、操作系统，网站浏览记录、软件使用记录、点击记录等);设备信息(包括硬件型号、设备MAC地址，操作系统类型、软件列表、设备唯一标识符(如:IME/android ID/IDFA/OPENUDID/GUID)、SIM卡的IMSI等);位置信息(包括出行信息、准确定位信息、住宿信息、经纬度等):": "上网记录(包括计算机IP地址、操作系统，网站浏览记录、软件使用记录、点击记录等);设备信息(包括硬件型号、设备MAC地址，操作系统类型、软件列表、设备唯一标识符(如:IME/androidID/IDFA/OPENUDID/GUID)、SIM卡的IMSI等);位置信息(包括出行信息、准确定位信息、住宿信息、经纬度等):",
    "由第三方服务提供商开立的账户信息；": "由第三方服务提供商开立的账户信息；",
    "用户在注册使用我们的服务或网站时，以及在使用我们的服务或网站过程中提供的任何其他信息;": "用户在注册使用我们的服务或网站时，以及在使用我们的服务或网站过程中提供的任何其他信息;",
    "可以提供用户交互总结的信息。": "可以提供用户交互总结的信息。",
    "您承诺不向我们提交任何不正确或误导性的信息，并同意将该等信息的何不准确内容或事改内容告知我们。taoify.shop保留单方面要求提供进一步文件以核实您所提供的信息的权利。": "您承诺不向我们提交任何不正确或误导性的信息，并同意将该等信息的何不准确内容或事改内容告知我们。taoify.shop保留单方面要求提供进一步文件以核实您所提供的信息的权利。",
    "如果您不希望我们收集上述信息/个人信息，您可随时以书面形式通知个人信息安全专员，并选择不收集其些信息，有关选择不收焦些信息的更多详情，请参阅下文“如何撤销同意，删除请求访问或更正您所提供的信息?”一节，但请注意，选择不收集某些信息或撤销授权我们收集，使用或处理您的个人信息将影响您对我们服务和平台的使用。例如，如果您选择不收集位置信息，基于位置的功能将被禁用。": "如果您不希望我们收集上述信息/个人信息，您可随时以书面形式通知个人信息安全专员，并选择不收集其些信息，有关选择不收焦些信息的更多详情，请参阅下文“如何撤销同意，删除请求访问或更正您所提供的信息?”一节，但请注意，选择不收集某些信息或撤销授权我们收集，使用或处理您的个人信息将影响您对我们服务和平台的使用。例如，如果您选择不收集位置信息，基于位置的功能将被禁用。",
    "其他信息的收集": "其他信息的收集",
    "与大多数网站和移动应用程序一样，您的设备发送的信息可句括您浏览我们平台时weh服务器记录的有关您的数据，我们收集的信息可能包括但不限于:您的互联网通信协议(IP)地址，计算机/移动设备操作系统和浏览器类型，移动设备类型，移动设备特征，移动设备唯一设备标识符(UDID)或移动设备标识符(MEID)，参考网站地址(如有)，以及您在我们的网站和移动应用程序上访回的页面和访问时间，有时还包括帮助网站记录您的最后一次访问时间的“cookie”(可被浏览 器禁用)。如果您已经登录，这些信息将与您的账户相关联。这些信息也包含在匿名统计中以帮助我们了解访问者如何使用我们的网站。": "与大多数网站和移动应用程序一样，您的设备发送的信息可句括您浏览我们平台时weh服务器记录的有关您的数据，我们收集的信息可能包括但不限于:您的互联网通信协议(IP)地址，计算机/移动设备操作系统和浏览器类型，移动设备类型，移动设备特征，移动设备唯一设备标识符(UDID)或移动设备标识符(MEID)，参考网站地址(如有)，以及您在我们的网站和移动应用程序上访回的页面和访问时间，有时还包括帮助网站记录您的最后一次访问时间的“cookie”(可被浏览器禁用)。如果您已经登录，这些信息将与您的账户相关联。这些信息也包含在匿名统计中以帮助我们了解访问者如何使用我们的网站。",
    "我们的移动应用程序可的会使用GPS Wi-Fi和其他技术来收集关于您移动设备位置的准确信息，我们可能会出于一项或多项目的收售 使用披露和/或处理上述数据，这些目的包括但不限于:基于位置的服务请求，根据您的位置向您发送相关内容，以及允许您通过我们移动应用程序中的服务与其他用户共享您的位置，对于大多数移动设备，您可通过设备来禁止我们访问您的位害信息，如果您对如何禁用移动设备定位服务有任何疑问，请与您的设备服务提供商或制造商联系。": "我们的移动应用程序可的会使用GPSWi-Fi和其他技术来收集关于您移动设备位置的准确信息，我们可能会出于一项或多项目的收售使用披露和/或处理上述数据，这些目的包括但不限于:基于位置的服务请求，根据您的位置向您发送相关内容，以及允许您通过我们移动应用程序中的服务与其他用户共享您的位置，对于大多数移动设备，您可通过设备来禁止我们访问您的位害信息，如果您对如何禁用移动设备定位服务有任何疑问，请与您的设备服务提供商或制造商联系。",
    "COOKIES": "COOKIES",
    "什么是Cookies?":"什么是Cookies?",
    "Cookies是我们发送到您的计算机或移动设备的标识符。“Cookies”可让网站记住您的操作和偏好设置(例如登录信息和区域选择)，从而改进您的浏览体验。这意味着您无需在每次返回网站时或在从一个页面浏览到另一个页面时重新输入此类信息。同时，“Cookies”允许我们识别您的计复机或设备，告诉我们服务或网站如何以及何时被使用或访问，访问者的数量，并跟踪我们网站上的活动。": "Cookies是我们发送到您的计算机或移动设备的标识符。“Cookies”可让网站记住您的操作和偏好设置(例如登录信息和区域选择)，从而改进您的浏览体验。这意味着您无需在每次返回网站时或在从一个页面浏览到另一个页面时重新输入此类信息。同时，“Cookies”允许我们识别您的计复机或设备，告诉我们服务或网站如何以及何时被使用或访问，访问者的数量，并跟踪我们网站上的活动。",
    "我们为什么使用Cookies?": "我们为什么使用Cookies?",
    "我们因如下原因使用Cookies:由于技术上的原因，需要一些Cookies来运行我们的网站，我们将这些称为基本的网站Cookies。第三方通过我们的网站提供Cookies，用于网站数据分析和其他目的，我们将这些称为系统性分析Cookies.": "我们因如下原因使用Cookies:由于技术上的原因，需要一些Cookies来运行我们的网站，我们将这些称为基本的网站Cookies。第三方通过我们的网站提供Cookies，用于网站数据分析和其他目的，我们将这些称为系统性分析Cookies.",
    "下表描述了通过我们的网站提供的第一方和第三方Cookies的具体类型及其执行目的:": "下表描述了通过我们的网站提供的第一方和第三方Cookies的具体类型及其执行目的:",
    "Cookie的种类": "Cookie的种类",
    "基础的网站Cookies": "基础的网站Cookies",
    "这些Cookies收集的信息，在为您提供我们网站的服务并使用其中的一些功能时使用，例如我们需要知道您当前的登录状态以判断是否为会员。": "这些Cookies收集的信息，在为您提供我们网站的服务并使用其中的一些功能时使用，例如我们需要知道您当前的登录状态以判断是否为会员。",
    "系统性分析Cookies": "系统性分析Cookies",
    "这些Cookies收集的信息，以多种形式帮助我们了解如何使用我们的网站访问情况如何，或帮助我们为您定制我们的网站。": "这些Cookies收集的信息，以多种形式帮助我们了解如何使用我们的网站访问情况如何，或帮助我们为您定制我们的网站。",
    "功能": "功能",
    "用户登录": "用户登录",
    "通知公告": "通知公告",
    "数据分析网站": "数据分析网站",
    "期限": "期限",
    "永久": "永久",
    "如何控制Cookies": "如何控制Cookies",
    "您可以设置或修改您的网页浏览器控件，以接受或拒绝Cookies，如果您选择拒绝Cookies，您仍然可以使用我们的网站，此时您在访问我们网站的一些功能和区域时可能会受限，您拒绝使用Cookies的方式会因浏览器的不同而不同，您可以通讨点击下面主流浏资器的链接来设置您的 Cookies偏好。": "您可以设置或修改您的网页浏览器控件，以接受或拒绝Cookies，如果您选择拒绝Cookies，您仍然可以使用我们的网站，此时您在访问我们网站的一些功能和区域时可能会受限，您拒绝使用Cookies的方式会因浏览器的不同而不同，您可以通讨点击下面主流浏资器的链接来设置您的Cookies偏好。",
    "浏览和下载内容和广告": "浏览和下载内容和广告",
    "当您在我们的网站或通讨我们的服务浏览内容，广告和访问其他软件时，系统会向我们发送信息，这些信息与您浏览我们的网页或移动应用释放时发送的信息基本相同(包括但不限于IP地址，操作系统等);但与网页浏览不同的是，您的设备将向我们传输与您浏览的内容和广告相关的信息，和/或与我们的服务、安装在我们网站上的软件和访问时间相关的信息。": "当您在我们的网站或通讨我们的服务浏览内容，广告和访问其他软件时，系统会向我们发送信息，这些信息与您浏览我们的网页或移动应用释放时发送的信息基本相同(包括但不限于IP地址，操作系统等);但与网页浏览不同的是，您的设备将向我们传输与您浏览的内容和广告相关的信息，和/或与我们的服务、安装在我们网站上的软件和访问时间相关的信息。",
    "taoify.shop如何使用您提供的信息?": "taoify.shop如何使用您提供的信息?",
    "我们可能出于以下一项或多项目的收集，使用，披露和/或处理您的个人信息:": "我们可能出于以下一项或多项目的收集，使用，披露和/或处理您的个人信息:",
    "考虑和/或处理您与我们的申请/交易，或您通过本服务与第三方的交易或通信:": "考虑和/或处理您与我们的申请/交易，或您通过本服务与第三方的交易或通信:",
    "管理、运营、提供和/或处理您对本服务和平台的使用和/或访问(包括但不限于记录您的偏好)，以及您与我们的关系和您开立的用户账户;": "管理、运营、提供和/或处理您对本服务和平台的使用和/或访问(包括但不限于记录您的偏好)，以及您与我们的关系和您开立的用户账户;",
    "回应，处理或完成交易和/或满足您对特定产品和服务的要求，以及向您告知服务问题和账户操作异常:": "回应，处理或完成交易和/或满足您对特定产品和服务的要求，以及向您告知服务问题和账户操作异常:",
    "在各区域执行我们的服务条款或任何适用的终端用户许可协议;": "在各区域执行我们的服务条款或任何适用的终端用户许可协议;",
    "保护人身安全、权利、财产安全或他人的安全;": "保护人身安全、权利、财产安全或他人的安全;",
    "识别和/或验证;": "识别和/或验证;",
    "维护和管理不定期的软件更新和/或为确保本服务顺利运作所需的其他更新和支持；": "维护和管理不定期的软件更新和/或为确保本服务顺利运作所需的其他更新和支持；",
    "处理或促进客户服务，执行您的指示，处理或回应由您或代表您(或声称由您或代表您)提出的所有询问；": "处理或促进客户服务，执行您的指示，处理或回应由您或代表您(或声称由您或代表您)提出的所有询问；",
    "为执行和/或管理您与我们的关系或您对本服务的使用，通过电话，短信和或传电子邮件和/或邮件或其他方式与您联系或沟通，但不限于向您传达与本服务有关的管理信息。您确认并同意，我们可能会通过邮寄信件、文件和通知与您沟通，在投递过程中，信封/邮件包裹外面可能会披露您的具体个人信息;": "为执行和/或管理您与我们的关系或您对本服务的使用，通过电话，短信和或传电子邮件和/或邮件或其他方式与您联系或沟通，但不限于向您传达与本服务有关的管理信息。您确认并同意，我们可能会通过邮寄信件、文件和通知与您沟通，在投递过程中，信封/邮件包裹外面可能会披露您的具体个人信息;",
    "允许其他用户在平台上与您互动或联系，包括当用户向您发送短信或在平台上发表关于您的评论时向您发出通知;": "允许其他用户在平台上与您互动或联系，包括当用户向您发送短信或在平台上发表关于您的评论时向您发出通知;",
    "开展研究分析和开发活动(包括但不限于数据分析问卷调查产品和服务开发和/或分析)，以分析您对我们服务的使用，改善我们的服务或产品和/或提高您的客户体验;": "开展研究分析和开发活动(包括但不限于数据分析问卷调查产品和服务开发和/或分析)，以分析您对我们服务的使用，改善我们的服务或产品和/或提高您的客户体验;",
    "允许审计和调查，以确定目标受众的规模和组成，并了解他们使用taoify.shop服务的体验;": "允许审计和调查，以确定目标受众的规模和组成，并了解他们使用taoify.shop服务的体验;",
    "出于营销和相关目的，向您发送taoify.shop(和/或其关联企业或关联公司)可能通过各种沟通方式销售;营销或推广的产品和/或服务(包括 但不限于可能与taoify.shop合作的第三方的产品和/或服务，或第三方与taoify.shop共同提供的产品和/或服务)。您可以通过电子营销资料中的退订功随时取消订阅接收营销信息，我们可能会通过您的联系信息向您发送关于我们和我们关联公司的消息": "出于营销和相关目的，向您发送taoify.shop(和/或其关联企业或关联公司)可能通过各种沟通方式销售;营销或推广的产品和/或服务(包括但不限于可能与taoify.shop合作的第三方的产品和/或服务，或第三方与taoify.shop共同提供的产品和/或服务)。您可以通过电子营销资料中的退订功随时取消订阅接收营销信息，我们可能会通过您的联系信息向您发送关于我们和我们关联公司的消息",
    "对法律程序作出反应或遭守相关司法管辖区的任何适用法律、政府要求或监管要求，包括但不限于道守任何法律规定下的、对taoify.shop或其关联公司或关联企业具有约束力的披露要求:": "对法律程序作出反应或遭守相关司法管辖区的任何适用法律、政府要求或监管要求，包括但不限于道守任何法律规定下的、对taoify.shop或其关联公司或关联企业具有约束力的披露要求:",
    "生成统计数据或进行研究，以满足内部及法定报告需要和/或记录保存:": "生成统计数据或进行研究，以满足内部及法定报告需要和/或记录保存:",
    "根据法律或监管义务，或根据法律要求或我们制定的风险管理程序，讲行尽职调查或其他审查活动(包括但不限于背导调查)": "根据法律或监管义务，或根据法律要求或我们制定的风险管理程序，讲行尽职调查或其他审查活动(包括但不限于背导调查)",
    "审计本服务或taoify.shop的业务;": "审计本服务或taoify.shop的业务;",
    "防止或调查任何确实或导似的taoify.shop政策违反行为，欺诈行为，非法活动，疏忽或不当行为，无论这些行为是否与您使用本服务有关或因您与我们的关系而产生:": "防止或调查任何确实或导似的taoify.shop政策违反行为，欺诈行为，非法活动，疏忽或不当行为，无论这些行为是否与您使用本服务有关或因您与我们的关系而产生:",
    "在您的管辖范围内或范围外存储，托管和备份您的个人信息(无论是用于灾后数据恢复还是其他目的)。": "在您的管辖范围内或范围外存储，托管和备份您的个人信息(无论是用于灾后数据恢复还是其他目的)。",
    "处理和/促进业务资产的交易或可能的企业资产交易，其中的参与者包括taoify.shop，或仅包括taoify.shop的关联公司或关联企业，或taoify.shop和/或taoify.shop的任何或多家关联公司或关联企业，可能还有其他第三方组织参与交易。“业务资产交易”是指对组织或组织的任何部分的任何业务或资产的收购，出售、租赁，整台、合并或任何其他收购，处置或融资;": "处理和/促进业务资产的交易或可能的企业资产交易，其中的参与者包括taoify.shop，或仅包括taoify.shop的关联公司或关联企业，或taoify.shop和/或taoify.shop的任何或多家关联公司或关联企业，可能还有其他第三方组织参与交易。“业务资产交易”是指对组织或组织的任何部分的任何业务或资产的收购，出售、租赁，整台、合并或任何其他收购，处置或融资;",
    "将信息用干本政策未明确规定的目的，以及我们在获取您同意时已告知您的任何其他目的。(合称为“目的”)": "将信息用干本政策未明确规定的目的，以及我们在获取您同意时已告知您的任何其他目的。(合称为“目的”)",
    "由于我们将/可能收集，使用，披露或处理您的个人信息的目的取决干实时发生的情况，所以该等目的可能未被包含在上述内容中。但是，除非相关法律允许我们在未经您同意的情况下处理适用信息，否则我们将在获取您的同意时向您告知该等目的。": "由于我们将/可能收集，使用，披露或处理您的个人信息的目的取决干实时发生的情况，所以该等目的可能未被包含在上述内容中。但是，除非相关法律允许我们在未经您同意的情况下处理适用信息，否则我们将在获取您的同意时向您告知该等目的。",
    "taoify.shop如何保护您的个人信息?": "taoify.shop如何保护您的个人信息?",
    "我们将在现有技术允许的情况下采取各种合理和必要的安全措施(包括但不限于物理，技术和行政措施)，以确保您的个人信息在我们的系统中是安全可靠的，从而最大限度地降低丢失，误用、未经授权访问，披露和变更的风险，由于各种恶意手段和科学技术的不断突破，您理解并同意，即使我们尽最大努力采取上述措施，您的个人信息仍有可能被泄露，捐坏或丢失。taoify.shop不做与此相关的任何承诺或保证。": "我们将在现有技术允许的情况下采取各种合理和必要的安全措施(包括但不限于物理，技术和行政措施)，以确保您的个人信息在我们的系统中是安全可靠的，从而最大限度地降低丢失，误用、未经授权访问，披露和变更的风险，由于各种恶意手段和科学技术的不断突破，您理解并同意，即使我们尽最大努力采取上述措施，您的个人信息仍有可能被泄露，捐坏或丢失。taoify.shop不做与此相关的任何承诺或保证。",
    "我们不向未成年人推荐我们的服务，除非当地法律允许并经监护人同意，否则不允许未成年人注册账户或将其姓名、地址、电话号码和电子邮件地址等个人信息发送给我们。如果我们意外收集了关于未成年人的信息，我们会在知悉后立即删除，如果您认为我们可能不恰当地持有来自或关于未成年人的信息，请与我们联系。": "我们不向未成年人推荐我们的服务，除非当地法律允许并经监护人同意，否则不允许未成年人注册账户或将其姓名、地址、电话号码和电子邮件地址等个人信息发送给我们。如果我们意外收集了关于未成年人的信息，我们会在知悉后立即删除，如果您认为我们可能不恰当地持有来自或关于未成年人的信息，请与我们联系。",
    "我们将按照有关隐私保护的法律法规和/或其他适用法律的规定保留您的个人信息，即如果我们有理由相信发生以下情况，则我们将销照您的个人信息或将其要名化外理:(1)该等个人信息的保留已不再符合收集个人信息的目的:(2)不再要要出于任何法律或业务目的而保留个人信息:以及(3)该等个人信息的进一步保留不受任何其他合法权益的保证。如果您停止使用我们的平台或您使用平台和/或服务的权利被终止，我们将按照适用法律的要求，根据本隐私政策和相关院私法规定的义务继续处理您的个人信息。": "我们将按照有关隐私保护的法律法规和/或其他适用法律的规定保留您的个人信息，即如果我们有理由相信发生以下情况，则我们将销照您的个人信息或将其要名化外理:(1)该等个人信息的保留已不再符合收集个人信息的目的:(2)不再要要出于任何法律或业务目的而保留个人信息:以及(3)该等个人信息的进一步保留不受任何其他合法权益的保证。如果您停止使用我们的平台或您使用平台和/或服务的权利被终止，我们将按照适用法律的要求，根据本隐私政策和相关院私法规定的义务继续处理您的个人信息。",
    "taoify.shop会向第三方披露其从访问者处收集的信息吗?": "taoify.shop会向第三方披露其从访问者处收集的信息吗?",
    "在开展业务的过程中，我们将/可能需要出于一项或多项目的，将处理后的您的个人信息披露给第三方服务提供商、代理商和/或我们的关联公司或关联企业和/或其他第三方(无论是否位千中国)，该等第三方服务提供商，代理商和/或关联公司或关联企业和/或其他第三方可代表我们或以其他方式出于上述一项或多项目的处理您的个人信息。第三方包括但不限于": "在开展业务的过程中，我们将/可能需要出于一项或多项目的，将处理后的您的个人信息披露给第三方服务提供商、代理商和/或我们的关联公司或关联企业和/或其他第三方(无论是否位千中国)，该等第三方服务提供商，代理商和/或关联公司或关联企业和/或其他第三方可代表我们或以其他方式出于上述一项或多项目的处理您的个人信息。第三方包括但不限于",
    "我们的子公司，关联公司和关联企业:": "我们的子公司，关联公司和关联企业:",
    "出于上述一项或多项目的而使用我们网站的用户": "出于上述一项或多项目的而使用我们网站的用户",
    "我们用于支持我们业务的承包商，代理商，服务提供商和其他第三方服务提供商“授权合作伙伴”)，包括但不限于向我们提供管理或其他服务的组织，如:邮寄公司，支付服务提供商，电信公司。信息技术公司和数据中心:我们仅会出于本隐私政策的合法，合理、必要，具体和明确的目的而共享您的信息，授权合作伙伴只能访问其履行职责所需的信息，目不得将此类信息用千任何其他目的。": "我们用于支持我们业务的承包商，代理商，服务提供商和其他第三方服务提供商“授权合作伙伴”)，包括但不限于向我们提供管理或其他服务的组织，如:邮寄公司，支付服务提供商，电信公司。信息技术公司和数据中心:我们仅会出于本隐私政策的合法，合理、必要，具体和明确的目的而共享您的信息，授权合作伙伴只能访问其履行职责所需的信息，目不得将此类信息用千任何其他目的。",
    "合并、剥离、改组、重组、解散或以其他方式出售或转让(无论是以持续经营的形式，还是作为破产、清算或类似程序的一部分)taoify.shop的部分或全部资产情况下的接收方，或taoify.shop的任何其他继承者，其中，taoify.shop持有的，有关服务卖方的个人信息也在转计资产中;或taoify.shop或其任何关联公司或关联企业参与的业务资产交易的对手方:": "合并、剥离、改组、重组、解散或以其他方式出售或转让(无论是以持续经营的形式，还是作为破产、清算或类似程序的一部分)taoify.shop的部分或全部资产情况下的接收方，或taoify.shop的任何其他继承者，其中，taoify.shop持有的，有关服务卖方的个人信息也在转计资产中;或taoify.shop或其任何关联公司或关联企业参与的业务资产交易的对手方:",
    "我们出于一项或多项目的向其披露个人信息的第三方，而该第三方又可能出于一项或多项目的收集和处理您的个人信息。": "我们出于一项或多项目的向其披露个人信息的第三方，而该第三方又可能出于一项或多项目的收集和处理您的个人信息。",
    "除其他事项外，可能需要与广告和项目提供商共享的信息还包括用户数量及其服务使用情况的统计数据。此信息不包括任何可用于识别或找到您的个人信息。": "除其他事项外，可能需要与广告和项目提供商共享的信息还包括用户数量及其服务使用情况的统计数据。此信息不包括任何可用于识别或找到您的个人信息。",
    "为免生疑问特此说明，如果隐私法律法规允许公司(即taoify.shop)在未经您同意的情况下收集、使用或披露您的个人信息，则本授权应继续适用。": "为免生疑问特此说明，如果隐私法律法规允许公司(即taoify.shop)在未经您同意的情况下收集、使用或披露您的个人信息，则本授权应继续适用。",
    "第三方可能会在非我方过错的情况下，非法拦截或访问传输到我们网站的个人信息或其中包含的个人信息，技术可能出现故障或无法按预期运行，或其他人可能访问，滥用或误用个人信息，我们将根据障私法的相关规定，采取合理的安全措施保护您的个人信息，但我们无法保证绝对的安全，包括但不限于并非由我们的过错，而是由恶意而老练的黑客造成的未经授权的披露。": "第三方可能会在非我方过错的情况下，非法拦截或访问传输到我们网站的个人信息或其中包含的个人信息，技术可能出现故障或无法按预期运行，或其他人可能访问，滥用或误用个人信息，我们将根据障私法的相关规定，采取合理的安全措施保护您的个人信息，但我们无法保证绝对的安全，包括但不限于并非由我们的过错，而是由恶意而老练的黑客造成的未经授权的披露。",
    "第三方收集的信息": "第三方收集的信息",
    "为了提供更好的客户服务和用户体验，平台使用Google.Inc(以下简称”Google”)提供的网页分析服务-Google Analytics，例如，Google Analytics使用cookies(您计算机上的文本文件)来帮助网站分析用户对平台的使用情况，由cookies生成的有关您网站使用情况的信息(包括您的IP地址)被发送并存储在Google的服务器上。Google将使用这些信息评估您的网站使用情况，为网站运营商编写活动报告，并提供与网站活动和互联网使用相关的其他服务，Google也可能被法律要求将这些信息传输给代表Google外理这些信息的第三方，Google不会将您的ip地址与 Google持有的其他数据关联起来。":"为了提供更好的客户服务和用户体验，平台使用Google.Inc(以下简称”Google”)提供的网页分析服务-Google Analytics，例如，Google Analytics使用cookies(您计算机上的文本文件)来帮助网站分析用户对平台的使用情况，由cookies生成的有关您网站使用情况的信息(包括您的IP地址)被发送并存储在Google的服务器上。Google将使用这些信息评估您的网站使用情况，为网站运营商编写活动报告，并提供与网站活动和互联网使用相关的其他服务，Google也可能被法律要求将这些信息传输给代表Google外理这些信息的第三方，Google不会将您的ip地址与 Google持有的其他数据关联起来。",
    "我们和第三方可能会在本服务中或通过本服务不时下载软件应用程序供您使用，这些应用程序可能单独访问并允许第三方查看您的身份信息(即您的姓名，用户ID，计算机地址或其他信息)，例如您以前安装的，或由第三方软件应用程序或网站为您安装的cookies。此外，这些应用程序可能要求您直接向第三方提供额外的信息。taoify.shop并未持有或控制通过这些应用程序提供的第三方产品或服务。我们建议您查看这些第三方在其网站或其他地方发布的条款和其他政策。": "我们和第三方可能会在本服务中或通过本服务不时下载软件应用程序供您使用，这些应用程序可能单独访问并允许第三方查看您的身份信息(即您的姓名，用户ID，计算机地址或其他信息)，例如您以前安装的，或由第三方软件应用程序或网站为您安装的cookies。此外，这些应用程序可能要求您直接向第三方提供额外的信息。taoify.shop并未持有或控制通过这些应用程序提供的第三方产品或服务。我们建议您查看这些第三方在其网站或其他地方发布的条款和其他政策。",
    "有关安全和第三方网站的免责声明": "有关安全和第三方网站的免责声明",
    "我们不保证您在第三方网站上提供的个人信息和/或其他信息的安全。为了维护我们持有或控制的、有关您的个人信息的安全，我们确实采取了各种安全措施。您的个人信息存储在受保护的网络中，仅供对网络具有特殊访问权限并被要求对此类个人信息保密的有限人员访问，当您使用本服务或向平台提交个人信息时，我们将提供一个安全服务器。您提供的所有个人信息将以加密方式存储在我们的数据库中，并只可按上述条款规定的方式访问。": "我们不保证您在第三方网站上提供的个人信息和/或其他信息的安全。为了维护我们持有或控制的、有关您的个人信息的安全，我们确实采取了各种安全措施。您的个人信息存储在受保护的网络中，仅供对网络具有特殊访问权限并被要求对此类个人信息保密的有限人员访问，当您使用本服务或向平台提交个人信息时，我们将提供一个安全服务器。您提供的所有个人信息将以加密方式存储在我们的数据库中，并只可按上述条款规定的方式访问。",
    "为了提供更高价值的服务，我们可选择将各类第三方网站链接至平台，并将第三方网站置于平台框架内。我们也可参与联合品牌活动或建立类似的关系，为访问者提供电子商务和其他服务和功能，此类链接网站有独立的隐私政策和安全措施。即使第三方与我们有关联，我们对此类链接网站也不具有管控权，而每个链接网站都有独立的隐私权和信息收集惯例，不受我们约束。我们不收集我们的合作品牌或第三方网站收集的信息(即便是在我们的网站上提供或通过我们的双站提供)。": "为了提供更高价值的服务，我们可选择将各类第三方网站链接至平台，并将第三方网站置于平台框架内。我们也可参与联合品牌活动或建立类似的关系，为访问者提供电子商务和其他服务和功能，此类链接网站有独立的隐私政策和安全措施。即使第三方与我们有关联，我们对此类链接网站也不具有管控权，而每个链接网站都有独立的隐私权和信息收集惯例，不受我们约束。我们不收集我们的合作品牌或第三方网站收集的信息(即便是在我们的网站上提供或通过我们的双站提供)。",
    "因此，我们不对此类链接网站的内容，安全措施(或缺多此类安全措施)和活动负责。此类链接网站仅为方便使用而提供，因此应自行承担风险。尽管如此，我们仍然努力保护平台及其中能接的完整性。因此，我们非常欢迎您就此类链接网站向我们提供您的意见(包括但不限于特定链接无法正常使用的情况)。": "因此，我们不对此类链接网站的内容，安全措施(或缺多此类安全措施)和活动负责。此类链接网站仅为方便使用而提供，因此应自行承担风险。尽管如此，我们仍然努力保护平台及其中能接的完整性。因此，我们非常欢迎您就此类链接网站向我们提供您的意见(包括但不限于特定链接无法正常使用的情况)。",
    "taoify.shop会把您的个人信息传输到国外吗?": "taoify.shop会把您的个人信息传输到国外吗?",
    "您的个人数据和/或信息可能会被传输到您所在国家/地区以外的地方存储或处理。在大多数情况下，您的个人信息是在美国处理的，因为我们的服务器位于美国，我们在这里运行我们的中央数据库。taoify.shop只会在符台相关隐私法律法规的情况下将您的信息传输到国外。": "您的个人数据和/或信息可能会被传输到您所在国家/地区以外的地方存储或处理。在大多数情况下，您的个人信息是在美国处理的，因为我们的服务器位于美国，我们在这里运行我们的中央数据库。taoify.shop只会在符台相关隐私法律法规的情况下将您的信息传输到国外。",
    "如何撤销同意，删除，请求访问或更正您所提供的信息?": "如何撤销同意，删除，请求访问或更正您所提供的信息?",
    "选择不收集些信息以及撒销同意": "选择不收集些信息以及撒销同意",
    "您可通过发送电子邮件至97668216@qq.com联系个人信息安全专员，选择不收集其些信息，并不再同意我们收集，使用和/或披露我们持有或控制的您的个人信息，我们将按照本隐私政策以及隐私法和其他活用法律下的相关义务行事，然而，通过选择不收集基些信息/撤销您的同意，即代表您同意，我们将无法继续向您提供我们的服务，并可能要求您终止您与我们之间的现有关系和/或有关服务的合同。": "您可通过发送电子邮件至97668216@qq.com联系个人信息安全专员，选择不收集其些信息，并不再同意我们收集，使用和/或披露我们持有或控制的您的个人信息，我们将按照本隐私政策以及隐私法和其他活用法律下的相关义务行事，然而，通过选择不收集基些信息/撤销您的同意，即代表您同意，我们将无法继续向您提供我们的服务，并可能要求您终止您与我们之间的现有关系和/或有关服务的合同。",
    "请求访问和/或更正您的个人信息。": "请求访问和/或更正您的个人信息。",
    "如果您已向我们注册，您可以通过您的账户经理或平台的相应页面访问和/或更正我们目前持有或控制的您的个人信息。如果您没有账户，您可以通过提交书面请求，请求访问和/或更正我们目前持有或控制的个人信息。您必须得供足够的信息，以便我们确定您的身份和您的请求的性质，从而处理您的请求。请通过发送电子邮件至97668216@qq.com(“个人信息安全专员”)提交您的书面请求": "如果您已向我们注册，您可以通过您的账户经理或平台的相应页面访问和/或更正我们目前持有或控制的您的个人信息。如果您没有账户，您可以通过提交书面请求，请求访问和/或更正我们目前持有或控制的个人信息。您必须得供足够的信息，以便我们确定您的身份和您的请求的性质，从而处理您的请求。请通过发送电子邮件至97668216@qq.com(“个人信息安全专员”)提交您的书面请求",
    "在某些情况下，我们会可能会向您收取合理的费用，以处理您访问个人信息的请求，如果需要收费，我们将以书面形式(包括电子邮件)向您提供项目和价格。请注意，除非您同意付费，否则我们无需回应或处理您的访问请求": "在某些情况下，我们会可能会向您收取合理的费用，以处理您访问个人信息的请求，如果需要收费，我们将以书面形式(包括电子邮件)向您提供项目和价格。请注意，除非您同意付费，否则我们无需回应或处理您的访问请求",
    "根据隐私法的规定，在既定情况下，企业有权在被要求和/或授权时拒绝更正您的个人信息，我们保留拒绝更正您的个人信息的权利": "根据隐私法的规定，在既定情况下，企业有权在被要求和/或授权时拒绝更正您的个人信息，我们保留拒绝更正您的个人信息的权利",
    "如有问题，疑虑或投诉，请与我们联系": "如有问题，疑虑或投诉，请与我们联系",
    "如果您对我们的隐私实践或与本服务相关的交易有任何问题或疑虑，请随时与我们联系": "如果您对我们的隐私实践或与本服务相关的交易有任何问题或疑虑，请随时与我们联系",
    "我们的隐私政策可能会变更": "我们的隐私政策可能会变更",
    "随着taoify.shop业务的发展，我们将更新本隐私政策，为您提供更好的服务。但是，未经您明确同意，您在本政策下的权利不会受到限制，我们将在taoify.shop网站上公布我们隐私政策的任何变更。": "随着taoify.shop业务的发展，我们将更新本隐私政策，为您提供更好的服务。但是，未经您明确同意，您在本政策下的权利不会受到限制，我们将在taoify.shop网站上公布我们隐私政策的任何变更。",
    "对于大变更，我们还将以更醒目的方式通知您(我们将通过包括但不限于电子邮件，短信或在网页上显示特别提醒等方式解释隐私政策的具体变更)。": "对于大变更，我们还将以更醒目的方式通知您(我们将通过包括但不限于电子邮件，短信或在网页上显示特别提醒等方式解释隐私政策的具体变更)。",
    "本隐私政策涉及的重大变更包括但不限于": "本隐私政策涉及的重大变更包括但不限于",
    "我们的服务模式发生重大变化(即个人信息处理的目的，个人信息处理的类型，个人信息的使用等);": "我们的服务模式发生重大变化(即个人信息处理的目的，个人信息处理的类型，个人信息的使用等);",
    "我们的控制权发生重大变化等(如因合并、收购、重组而导致的信息控制人变更);": "我们的控制权发生重大变化等(如因合并、收购、重组而导致的信息控制人变更);",
    "个人信息共享，转让或公开披露的主体发生变更；": "个人信息共享，转让或公开披露的主体发生变更；",
    "您参与个人信息处理的权利及其行使方式发生重大变更；": "您参与个人信息处理的权利及其行使方式发生重大变更；",
    "我们的部门、联系方式及个人信息安全处理渠道发生变更；": "我们的部门、联系方式及个人信息安全处理渠道发生变更；",
    "《个人信息安全影响评估报告》中指出的高风险。我们还将在taoify.shop上存档本隐私政策的旧版本，供您参考。": "《个人信息安全影响评估报告》中指出的高风险。我们还将在taoify.shop上存档本隐私政策的旧版本，供您参考。",
    "填写您所售商品": "填写您所售商品",
    "请输入您的电话号": "请输入您的电话号",
    "请输入您的邮箱": "请输入您的邮箱",
    "填写您的所在地": "填写您的所在地",
    "填写您的企业信息": "填写您的企业信息",
    "taoify一站式": "taoify一站式",
    "全球跨境电商平台": "全球跨境电商平台",
    "B2B、B2C垂直领域专家，旨在帮助供应商一站直达全球消费者，触达海量优质市场，把商品销往全球": "B2B、B2C垂直领域专家，旨在帮助供应商一站直达全球消费者，触达海量优质市场，把商品销往全球",
    "即刻入驻，商机无限": "即刻入驻，商机无限",
    "收录不限制品类，全球用户一站直达": "收录不限制品类，全球用户一站直达",
    "我们的优势": "我们的优势",
    "有买家": "有买家",
    "已有月活7亿的海外消费群体": "已有月活7亿的海外消费群体",
    "有资源": "有资源",
    "金流、物流、营销、管理一站式搞定，你只需要把商品交给我们": "金流、物流、营销、管理一站式搞定，你只需要把商品交给我们",
    "有效率": "有效率",
    "多种自动化工具，赋能运营，智能AI助力": "多种自动化工具，赋能运营，智能AI助力",
    "有经验": "有经验",
    "12年跨境经验，服务上千家企业，行业内高价值生态伙伴多": "12年跨境经验，服务上千家企业，行业内高价值生态伙伴多",
    "taoify渠道商": "taoify渠道商",
    "taoify渠道商入驻流程": "taoify渠道商入驻流程",
    "渠道商开通的客服联系方式": "渠道商开通的客服联系方式",
    "全球贸易，让您像做国内贸易一样简单！": "全球贸易，让您像做国内贸易一样简单！",
    "加入taoify，轻松完成全球销售": "加入taoify，轻松完成全球销售",
    "立即入驻": "立即入驻",
    "留电咨询获取商机": "留电咨询获取商机",
    "享受蓝海抢占先机": "享受蓝海抢占先机",
    "提交申请表": "提交申请表",
    "极简账号配备一件代发电商业务经营基本功能，包括绑定店铺，自动履单，自动提包等功能。新注册用户账号系统认设置为极简账号，在店铺经营过程中需进行订单操作，则默认授权由我们代运营团队代其处理，处理讨程中产生的服务或操作费用按taoify.shop官网公示的服务费用或事先双方协商的服务费用由系统自动扣缴或财务手动扣缴。": "极简账号配备一件代发电商业务经营基本功能，包括绑定店铺，自动履单，自动提包等功能。新注册用户账号系统认设置为极简账号，在店铺经营过程中需进行订单操作，则默认授权由我们代运营团队代其处理，处理讨程中产生的服务或操作费用按taoify.shop官网公示的服务费用或事先双方协商的服务费用由系统自动扣缴或财务手动扣缴。",
    "您在注册，使用我们服务的过程中，请您提供合法，真实，有效，准确并完整的资料(相据实际操作票要，可能包括真实姓名，国籍，营业执照，联系方式，从事职业通讯地址等)。为了能将账户信息变动及时通知到您，以及更好保障您的账户安全，如该等资料发生变更，请您及时通知我们或者自行在taoify.shop后台做调整更新。": "您在注册，使用我们服务的过程中，请您提供合法，真实，有效，准确并完整的资料(相据实际操作票要，可能包括真实姓名，国籍，营业执照，联系方式，从事职业通讯地址等)。为了能将账户信息变动及时通知到您，以及更好保障您的账户安全，如该等资料发生变更，请您及时通知我们或者自行在taoify.shop后台做调整更新。",
    "(三)存在如下情形时，我们可能会对您名下taoify.shop用户账户暂停或终止提供taoify.shop用户服务:": "(三)存在如下情形时，我们可能会对您名下taoify.shop用户账户暂停或终止提供taoify.shop用户服务:",
    "您违反了法律法规的规定或本协议的约定。": "您违反了法律法规的规定或本协议的约定。",
    "您的用户账户可能存在风险的(比如您的taoify.shop用户账户长期未被使用月存在一定安全隐患)。": "您的用户账户可能存在风险的(比如您的taoify.shop用户账户长期未被使用月存在一定安全隐患)。",
    "您在参加市场活动时涉及到侵权或违反商业道德，违反诚实信用原则。": "您在参加市场活动时涉及到侵权或违反商业道德，违反诚实信用原则。",
    "您遭到他人投诉，且对方已经得供了一定证据。": "您遭到他人投诉，且对方已经得供了一定证据。",
    "您可能错误地操作他人账户，或者将他人账户进行了身份验证。": "您可能错误地操作他人账户，或者将他人账户进行了身份验证。",
    "(四)taoify.shop用户服务规则": "(四)taoify.shop用户服务规则",
    "您用户账户的使用记录交易数据等均以我们的系统记录为准，如您对该等数据存有导议的，可及时向我们提出，我们会根据您提供的相关证据积极予以核实。": "您用户账户的使用记录交易数据等均以我们的系统记录为准，如您对该等数据存有导议的，可及时向我们提出，我们会根据您提供的相关证据积极予以核实。",
    "taoify.shop采用预付金额(账号充值)，后系统扣款、财务扣款/退款的方式来管理您的账户余额和资金流动，您在使用taoify.shop账号的相关服务时请确保账号余额充足。": "taoify.shop采用预付金额(账号充值)，后系统扣款、财务扣款/退款的方式来管理您的账户余额和资金流动，您在使用taoify.shop账号的相关服务时请确保账号余额充足。",
    "为了更好地向您提供服务，请您在使用taoify.shop用户服务过程中，遵守本协议内容，taoify.shop用户网站上出现的关于操作的提示及我们发送到您邮件的提示。": "为了更好地向您提供服务，请您在使用taoify.shop用户服务过程中，遵守本协议内容，taoify.shop用户网站上出现的关于操作的提示及我们发送到您邮件的提示。",
    "我们会以网站公告、电子邮件、站内信等方式向您发送通知，例如通知您交易进展情况，或者提示您进行相关操作，请您及时予以关注。": "我们会以网站公告、电子邮件、站内信等方式向您发送通知，例如通知您交易进展情况，或者提示您进行相关操作，请您及时予以关注。",
    "您需要自行承相使用我们服务期间的货币贬值，汇率波动等风险，您对我们代为收取(账户充值)或代为支付款项(用于买单打包等操作扣款)的金额享有对该等款项的管辖权和支配权。服务咨询与投诉": "您需要自行承相使用我们服务期间的货币贬值，汇率波动等风险，您对我们代为收取(账户充值)或代为支付款项(用于买单打包等操作扣款)的金额享有对该等款项的管辖权和支配权。服务咨询与投诉",
    "在使用我们服务的过程中，如遇到问题，您可以通过拨打+8615038350530或者通过在线客服等方式联系我们，为了保护您及他人的合法权益，如您被他人投诉或投诉他人，我们可能会将您的姓名及身份信息联系方式，企业经营范围投诉相关内容等提供给管辖权的仲裁机关，以便及时解决投诉纠纷；同时，出于维护您或他人的生命、财产等重大合法权益但又很难得到您本人同意的情况下，我们可能会将您的姓名及身份信息、联系方式、企业经营范围、投诉相关内容等提供给争议相对方，但法律法规明确禁止提供的除外。": "在使用我们服务的过程中，如遇到问题，您可以通过拨打+8615038350530或者通过在线客服等方式联系我们，为了保护您及他人的合法权益，如您被他人投诉或投诉他人，我们可能会将您的姓名及身份信息联系方式，企业经营范围投诉相关内容等提供给管辖权的仲裁机关，以便及时解决投诉纠纷；同时，出于维护您或他人的生命、财产等重大合法权益但又很难得到您本人同意的情况下，我们可能会将您的姓名及身份信息、联系方式、企业经营范围、投诉相关内容等提供给争议相对方，但法律法规明确禁止提供的除外。",
    "(五)我们的承诺和声明": "(五)我们的承诺和声明",
    "您账号中收到的充值款项，会一直以余额的形式存储在您的taoify.shop账号中，我们不会挪作他用。": "您账号中收到的充值款项，会一直以余额的形式存储在您的taoify.shop账号中，我们不会挪作他用。",
    "您使用taoify.shop账号能享受一件代发和店捕代运营全部相关服务。": "您使用taoify.shop账号能享受一件代发和店捕代运营全部相关服务。",
    "(六)服务风险提示": "(六)服务风险提示",
    "在使用我们的服务时，若您未遵从本协议，事先约定的协议(SOP)或相关网站说明，页面中的操作提示，规则，则我们有权拒绝为您提供相应服务，且我们免于承担损害赔信责任。": "在使用我们的服务时，若您未遵从本协议，事先约定的协议(SOP)或相关网站说明，页面中的操作提示，规则，则我们有权拒绝为您提供相应服务，且我们免于承担损害赔信责任。",
    "请您特别注意，如在taoify.shop用户网站上以页面标明或其他方式表明相关服务系由第三方提供，您在使用该服务过程中如有疑问或发生纠纷，请您与第三方协商解决。": "请您特别注意，如在taoify.shop用户网站上以页面标明或其他方式表明相关服务系由第三方提供，您在使用该服务过程中如有疑问或发生纠纷，请您与第三方协商解决。",
    "taoify.shop销售的商品均采购自中华人民共和国，主要符合中华人民共和国有关商品质量、安全、卫生、环保、标识等标准，可能与您所在国家的标准存在差异或冲突，您须自行辨别并承担相关风险。": "taoify.shop销售的商品均采购自中华人民共和国，主要符合中华人民共和国有关商品质量、安全、卫生、环保、标识等标准，可能与您所在国家的标准存在差异或冲突，您须自行辨别并承担相关风险。",
    "(七)服务费用": "(七)服务费用",
    "在您使用我们的服务时，我们有权向您收取服务或者操作费用，具体服务用以您使用我们服务时taoify.shop官方网站展示的价格或您与我们达成的其他书面协议价格为准。除非另有说明或约定，当您完成账户注册时起，则默认授权我们代为收取或代为扣缴您的账户余额作为平台使用的服务或者操作费用。": "在您使用我们的服务时，我们有权向您收取服务或者操作费用，具体服务用以您使用我们服务时taoify.shop官方网站展示的价格或您与我们达成的其他书面协议价格为准。除非另有说明或约定，当您完成账户注册时起，则默认授权我们代为收取或代为扣缴您的账户余额作为平台使用的服务或者操作费用。",
    "(一)您需要遵守中华人民共和国相关法律法规及您所属、所居住或开展经营活动或其他业务的国家或地区的法律法规，不得将我们的服务用于非法目的(包括用于禁止或限制交易物品的交易)，也不得以任何非法方式使用我们的服务。": "(一)您需要遵守中华人民共和国相关法律法规及您所属、所居住或开展经营活动或其他业务的国家或地区的法律法规，不得将我们的服务用于非法目的(包括用于禁止或限制交易物品的交易)，也不得以任何非法方式使用我们的服务。",
    "(二)您不得和用我们的服务从事得害他人合法权益之行为或违反国家法律法规，否则我们有权讲行调查，延迟或停止提供服务，且您要自行承担相关法律责任，如因此导致我们或其他方受损的，您需要承担赔偿责任。": "(二)您不得和用我们的服务从事得害他人合法权益之行为或违反国家法律法规，否则我们有权讲行调查，延迟或停止提供服务，且您要自行承担相关法律责任，如因此导致我们或其他方受损的，您需要承担赔偿责任。",
    "(三)上述1和2适用的情况包括但不限于:": "(三)上述1和2适用的情况包括但不限于:",
    "侵害他人名誉权、隐私权、商业秘密、商标权、著作权、专利权等合法权益；": "侵害他人名誉权、隐私权、商业秘密、商标权、著作权、专利权等合法权益；",
    "违反保密义务；": "违反保密义务；",
    "冒用/盗用他人账号使用我们的服务；": "冒用/盗用他人账号使用我们的服务；",
    "涉嫌不法交易行为，如贩卖枪支，毒品，禁药，盗版软件，黄色污秽物品，其他我们认为不得使用我们的平台服务进行交易的物品等；": "涉嫌不法交易行为，如贩卖枪支，毒品，禁药，盗版软件，黄色污秽物品，其他我们认为不得使用我们的平台服务进行交易的物品等；",
    "从事可能侵害我们的服务系统、系统知识产权或数据的行为。": "从事可能侵害我们的服务系统、系统知识产权或数据的行为。",
    "(四)账户仅限本人使用，承诺按流程开立和使用本人账户": "(四)账户仅限本人使用，承诺按流程开立和使用本人账户",
    "(五)您理解，我们的服务有赖于系统的准确运行及操作，若出现系统差错，故障等情形，您同意我们可以采取更正差错，扣划款项等适当纠正措施。": "(五)您理解，我们的服务有赖于系统的准确运行及操作，若出现系统差错，故障等情形，您同意我们可以采取更正差错，扣划款项等适当纠正措施。",
    "(六)您不得对我们的系统和程序采取反向工程手段进行破解，不得对上述系统和程序(包括但不限于源程序、目标程序、技术文档、服务器数据)进行复制、修改编译整合或算改，不得修改成增减我们系统的功能。": "(六)您不得对我们的系统和程序采取反向工程手段进行破解，不得对上述系统和程序(包括但不限于源程序、目标程序、技术文档、服务器数据)进行复制、修改编译整合或算改，不得修改成增减我们系统的功能。",
    "(七)您不得自行或允许第三方通过非taoify.shop用户官方程序、机器、脚本、爬虫、或其他自动化的方法使用taoify.shop用户服务或获取taoify.shop用户服务相关数据。您仅可通过本人人工操作的方式或者taoify.shop官方代运营操作的方式来使用taoify.shop用户服务。": "(七)您不得自行或允许第三方通过非taoify.shop用户官方程序、机器、脚本、爬虫、或其他自动化的方法使用taoify.shop用户服务或获取taoify.shop用户服务相关数据。您仅可通过本人人工操作的方式或者taoify.shop官方代运营操作的方式来使用taoify.shop用户服务。",
    "(一)用户权益保障承诺": "(一)用户权益保障承诺",
    "我们一直事承“极致服务，客户第一”的原则，努力为您在国际电商经营中保驾护航。为保障您在使用服务时的合法权益，我们向您承诺保障您的知情权，自主选择权、公平交易权、受尊重权和客户信息安全权。": "我们一直事承“极致服务，客户第一”的原则，努力为您在国际电商经营中保驾护航。为保障您在使用服务时的合法权益，我们向您承诺保障您的知情权，自主选择权、公平交易权、受尊重权和客户信息安全权。",
    "(二)用户资金保障": "(二)用户资金保障",
    "我们始终重视保护您的资金安全，承诺不挪用，占用用户资金。如您在使用我们服务的过程中，由于您的taoify.shop用户账户被盗用造成资金损失，我们将按照责任双方台同的约定为您提供资金保障和赔偿。": "我们始终重视保护您的资金安全，承诺不挪用，占用用户资金。如您在使用我们服务的过程中，由于您的taoify.shop用户账户被盗用造成资金损失，我们将按照责任双方台同的约定为您提供资金保障和赔偿。",
    "(三)信息保护": "(三)信息保护",
    "出于技术原因和提升您的用户体验需要，我们将通过Cookies技术，当您的网络浏览器访问taoify.shop服务或由我们或代表我们在其它网站上提供的其他内容时，自动收集并存储您在taoify.shop的使用，行为信息，并在经过数据脱敏使之不再指向，关联到您个人的身份信息时，自由使用脱敏后的纯商业数据。我们将始终高度重视用户的信息保护。未经您的同意，不会将您的个人资料，商业秘密等信息供给何第三方。如果您相删除cookies，或进一步了解收集的信息，我们希望您认真并完整党读我们的《隐私政策》，这将更有助于保障您的个人信息安全。": "出于技术原因和提升您的用户体验需要，我们将通过Cookies技术，当您的网络浏览器访问taoify.shop服务或由我们或代表我们在其它网站上提供的其他内容时，自动收集并存储您在taoify.shop的使用，行为信息，并在经过数据脱敏使之不再指向，关联到您个人的身份信息时，自由使用脱敏后的纯商业数据。我们将始终高度重视用户的信息保护。未经您的同意，不会将您的个人资料，商业秘密等信息供给何第三方。如果您相删除cookies，或进一步了解收集的信息，我们希望您认真并完整党读我们的《隐私政策》，这将更有助于保障您的个人信息安全。",
    "(一)免责条款": "(一)免责条款",
    "因下列原因导致我们无法正常提供服务，我们免于承担责任:": "因下列原因导致我们无法正常提供服务，我们免于承担责任:",
    "我们的系统停机维护或升级;": "我们的系统停机维护或升级;",
    "因台风，地震，洪水、雷电或恐怖袭击等不可抗力原因:": "因台风，地震，洪水、雷电或恐怖袭击等不可抗力原因:",
    "您的电子设备软硬件和通信线路，供电线路出现故障:": "您的电子设备软硬件和通信线路，供电线路出现故障:",
    "因您操作不当或通过非经我们授权或认可的方式使用我们服务:": "因您操作不当或通过非经我们授权或认可的方式使用我们服务:",
    "因病毒，木马，恶意程序攻击，网络拥堵，系统不稳定，系统或设备故障，通讯故障，电力故障，银行原因，第三方服务瑕疵或政府行为等原因。": "因病毒，木马，恶意程序攻击，网络拥堵，系统不稳定，系统或设备故障，通讯故障，电力故障，银行原因，第三方服务瑕疵或政府行为等原因。",
    "尽管有前款约定，我们将采取合理行动积极促使服务恢复正常。": "尽管有前款约定，我们将采取合理行动积极促使服务恢复正常。",
    "(二)责任限制": "(二)责任限制",
    "我们可能同时为您及您的竞争对手方提供服务，您同意对我们可能存在的该等行为予以明确豁免任何实际或潜在的利益冲突，并不得以此主张我们在提供服务时存在法律上的瑕疵。": "我们可能同时为您及您的竞争对手方提供服务，您同意对我们可能存在的该等行为予以明确豁免任何实际或潜在的利益冲突，并不得以此主张我们在提供服务时存在法律上的瑕疵。",
    "为了您更好地使用taoify.shop用户服务，您还需要阅读并遵守taoify.shop用户网站公示的其他协议或规则。前述协议或规则的部分条款被认定无效的，不影响其他内容的效力。": "为了您更好地使用taoify.shop用户服务，您还需要阅读并遵守taoify.shop用户网站公示的其他协议或规则。前述协议或规则的部分条款被认定无效的，不影响其他内容的效力。",
    "我们及关联公司的系统及taoify.shop网站上的内容，包括但不限于图片、网站架构、网站画面的安排、网页设计，均属于我们公司依法拥有的知识产权，包括但不限于商标权、专利权、商业秘密等。": "我们及关联公司的系统及taoify.shop网站上的内容，包括但不限于图片、网站架构、网站画面的安排、网页设计，均属于我们公司依法拥有的知识产权，包括但不限于商标权、专利权、商业秘密等。",
    "非经我们或关联公司书面同意，请勿擅自使用，修改，反向编译，复制，公开传播，改变，散布，发行或公开发表BuckvDrop网站程序或内容。": "非经我们或关联公司书面同意，请勿擅自使用，修改，反向编译，复制，公开传播，改变，散布，发行或公开发表BuckvDrop网站程序或内容。",
    "尊重知识产权是您应尽的义务，如有违反，您需要承担损害赔偿责任。": "尊重知识产权是您应尽的义务，如有违反，您需要承担损害赔偿责任。",
    "taoify.shop销售的商品主要符合中华人民共和国的相关知识产权，但因知识产权的地域性因素影响，可能会侵犯您所在国家的相关知识产权，您须自行辨别，并放弃向taoify.shop平台主张索赔的权利。": "taoify.shop销售的商品主要符合中华人民共和国的相关知识产权，但因知识产权的地域性因素影响，可能会侵犯您所在国家的相关知识产权，您须自行辨别，并放弃向taoify.shop平台主张索赔的权利。",
    "如果任何组织或个人认为taoify.shop网站上的任何内容可能侵犯其知识产权，可以通过在线客服或邮箱97668216@qq.com及时通知我们，我们会在收到通知后核实，并删除侵权内容，但不承拍任何相关法律责任": "如果任何组织或个人认为taoify.shop网站上的任何内容可能侵犯其知识产权，可以通过在线客服或邮箱97668216@qq.com及时通知我们，我们会在收到通知后核实，并删除侵权内容，但不承拍任何相关法律责任",
    "本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本协议产生的争议，均应依照中华人民共和国法律予以处理。taoify.shop拥有和保留本协议的最终解释权。": "本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本协议产生的争议，均应依照中华人民共和国法律予以处理。taoify.shop拥有和保留本协议的最终解释权。",
    "因本协议看行所产生的一切争议，双方应首先友好协商解决，如协商不成的，仟何一方有权向深圳国际仲裁院申请仲裁。仲裁地为中国广东省深圳市仲裁语言为中文。": "因本协议看行所产生的一切争议，双方应首先友好协商解决，如协商不成的，仟何一方有权向深圳国际仲裁院申请仲裁。仲裁地为中国广东省深圳市仲裁语言为中文。",
    "taoify.shop用户注册协议": "taoify.shop用户注册协议",
    "taoify.shop用户服务协议的确认": "taoify.shop用户服务协议的确认",
    "taoify.shop用户服务相关定义": "taoify.shop用户服务相关定义",
    "taoify.shop为您提供的服务内容": "taoify.shop为您提供的服务内容",
    "账户的注册，使用和注销": "账户的注册，使用和注销",
    "taoify.shop自动扣款授权": "taoify.shop自动扣款授权",
    "使用taoify.shop用户服务的注意事项": "使用taoify.shop用户服务的注意事项",
    "用户合法使用taoify.shop服务的承诺": "用户合法使用taoify.shop服务的承诺",
    "用户权益保障及信息保护": "用户权益保障及信息保护",
    "不可抗力、免责及责任限制": "不可抗力、免责及责任限制",
    "配套规则和协议": "配套规则和协议",
    "知识产权的保护": "知识产权的保护",
    "法律适用": "法律适用",
    "感谢您选择taoify.shop服务taoify.shop用户注册协议(以下简称本协议”)由taoify.shop(我们”)和您答订。taoify.shop的各项电子服务的所有权和运作权归taoify.shop所有，用户同意所有注册条款并完成注册程序，才能成为taoify.shop正式用户，本协议条款是处理双方权利义务的契约，始终有效。": "感谢您选择taoify.shop服务taoify.shop用户注册协议(以下简称本协议”)由taoify.shop(我们”)和您答订。taoify.shop的各项电子服务的所有权和运作权归taoify.shop所有，用户同意所有注册条款并完成注册程序，才能成为taoify.shop正式用户，本协议条款是处理双方权利义务的契约，始终有效。",
    "一、taoify.shop用户服务协议的确认": "一、taoify.shop用户服务协议的确认",
    "用户在使用taoify.shop所提供的各项服务的同时，承诺接受并遵守各项相关规则的规定，用户确认本服务协议后，本服务协议即在用户和taoify.shop之间产生法律效力，请用户务必在使用之前认真阅读全部服务协议内容，如有何疑问，您可通过taoify.shop官方网站的在线客服或邮箱97668216@qq.com进行咨询，以便我们为您解释和说明，您通过页面点击或其他方式确认即表示您已同意本协议。": "用户在使用taoify.shop所提供的各项服务的同时，承诺接受并遵守各项相关规则的规定，用户确认本服务协议后，本服务协议即在用户和taoify.shop之间产生法律效力，请用户务必在使用之前认真阅读全部服务协议内容，如有何疑问，您可通过taoify.shop官方网站的在线客服或邮箱97668216@qq.com进行咨询，以便我们为您解释和说明，您通过页面点击或其他方式确认即表示您已同意本协议。",
    "为给您提供更好的服务以及随着taoify.shop业务的发展，本协议也会随之更新。如我们对本协议进行修改，我们将通过在taoify.shop网站上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新。对于重大变更，我们还会提供更为显著的通知(我们会通过包括但不限于邮件短信或在浏览页面做特别提示等方式，说明本协议的具体变更内容)，若您无法同意变更修改后的协议内容，您有权停止使用相关服务;双方协商一致的，也可另行变更相关服务和对应协议内容，登录或继续使用“服务”将表示您接受并同章协议修改后的全部内容": "为给您提供更好的服务以及随着taoify.shop业务的发展，本协议也会随之更新。如我们对本协议进行修改，我们将通过在taoify.shop网站上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新。对于重大变更，我们还会提供更为显著的通知(我们会通过包括但不限于邮件短信或在浏览页面做特别提示等方式，说明本协议的具体变更内容)，若您无法同意变更修改后的协议内容，您有权停止使用相关服务;双方协商一致的，也可另行变更相关服务和对应协议内容，登录或继续使用“服务”将表示您接受并同章协议修改后的全部内容",
    "您需遵守您所属国家或地区的法律，目您确认，订立并履行本协议不违反您所属，所居住或开展经营活动或其他业务的国家或地区的法律法规。": "您需遵守您所属国家或地区的法律，目您确认，订立并履行本协议不违反您所属，所居住或开展经营活动或其他业务的国家或地区的法律法规。",
    "二、taoify.shop用户服务相关定义": "二、taoify.shop用户服务相关定义",
    "taoify.shop用户账户(或称”账户”:指在您通过平台注册并通过taoify.shop用户身份验证后，我们为您开立的平台使用账户。": "taoify.shop用户账户(或称”账户”:指在您通过平台注册并通过taoify.shop用户身份验证后，我们为您开立的平台使用账户。",
    "taoify.shop用户登录邮箱:指您设置的用于登录用户账户的电子邮箱。": "taoify.shop用户登录邮箱:指您设置的用于登录用户账户的电子邮箱。",
    "身份要素：指我们用于识别您身份的信息要素，包括但不限于您的taoify.shop用户账户、密码、邮箱校验码、电话号码、手机号码等信息。": "身份要素：指我们用于识别您身份的信息要素，包括但不限于您的taoify.shop用户账户、密码、邮箱校验码、电话号码、手机号码等信息。",
    "taoify.shop用户网站:除本协议另有规定外，指taoify.shop及/或taoify.shop用户操作后台。": "taoify.shop用户网站:除本协议另有规定外，指taoify.shop及/或taoify.shop用户操作后台。",
    "余额管理:指基于taoify.shop用户账户在您经营过程中对账号进行的充值、扣款、提现等服务。": "余额管理:指基于taoify.shop用户账户在您经营过程中对账号进行的充值、扣款、提现等服务。",
    "taoify.shop极简用户账号:指用户初次使用taoify.shop网站进行注册成功后获得使用的用户账号。": "taoify.shop极简用户账号:指用户初次使用taoify.shop网站进行注册成功后获得使用的用户账号。",
    "taoify.shop平台:指taoify.shop自主开发并与其系统完美对接的独立站建站工具。": "taoify.shop平台:指taoify.shop自主开发并与其系统完美对接的独立站建站工具。",
    "一件代发服务:指为您采购中国境内一个或多个商品并在打包完成后寄送到您的指定收货地址的整个服务流程。": "一件代发服务:指为您采购中国境内一个或多个商品并在打包完成后寄送到您的指定收货地址的整个服务流程。",
    "店铺代运营服务:指为您代运营和管理您的独立站店铺。具体是指自受您委托之时起(签订店铺代运营合同/SOP)，基于您选择的店铺服务项对您的店铺进行代运营操作的整个过程。": "店铺代运营服务:指为您代运营和管理您的独立站店铺。具体是指自受您委托之时起(签订店铺代运营合同/SOP)，基于您选择的店铺服务项对您的店铺进行代运营操作的整个过程。",
    "三、taoify.shop为您提供的服务内容": "三、taoify.shop为您提供的服务内容",
    "taoify.shop用户服务是我们通过极简用户账号+代运营操作模式向您提供受您委托的产品一件代发、店铺代运营(仅限于BuckyShop/Shopifv/Woocommerce店铺)等国际电商业务运营管理服务。": "taoify.shop用户服务是我们通过极简用户账号+代运营操作模式向您提供受您委托的产品一件代发、店铺代运营(仅限于BuckyShop/Shopifv/Woocommerce店铺)等国际电商业务运营管理服务。",
    "(一)基本服务": "(一)基本服务",
    "taoify.shop默认免费为所有用户提供以下软件功能和服务，足以让您自助一站式开启电商事业:": "taoify.shop默认免费为所有用户提供以下软件功能和服务，足以让您自助一站式开启电商事业:",
    "建店/关联店铺，支持绑定Shopifv/WooCommerce/taoify.shop店铺平台。taoify.shop为您一键创建独立站店铺，同时taoify.shop能支持绑定您的独立站店铺并提供选择商品、订单管理、包裹管理和国际物流等全流程电商服务。": "建店/关联店铺，支持绑定Shopifv/WooCommerce/taoify.shop店铺平台。taoify.shop为您一键创建独立站店铺，同时taoify.shop能支持绑定您的独立站店铺并提供选择商品、订单管理、包裹管理和国际物流等全流程电商服务。",
    "海量商品搜索/上架，支持中国电商平台(淘宝、天猫、1688)。": "海量商品搜索/上架，支持中国电商平台(淘宝、天猫、1688)。",
    "专业的客户经理为您提供包含店铺运营，产品采购，质量检验，仓储，包裹服务，物流和售后服务的一站式解决方案。": "专业的客户经理为您提供包含店铺运营，产品采购，质量检验，仓储，包裹服务，物流和售后服务的一站式解决方案。",
    "(二)服务项详情": "(二)服务项详情",
    "基础服务，基础服务是您在使用系统时涉及的服务项。taoify.shop为每位用户提供一定的免费次数，您需为超出的次数付费。包括对接的第三方平台，商品搜索/编辑、订单同步到taoify.shop全球寄送等基础服务。": "基础服务，基础服务是您在使用系统时涉及的服务项。taoify.shop为每位用户提供一定的免费次数，您需为超出的次数付费。包括对接的第三方平台，商品搜索/编辑、订单同步到taoify.shop全球寄送等基础服务。",
    "增值服务，增值服务仅在商品采购、仓储打包、国际物流发货过程中可能产生收费。该服务包括商品增值服务(到付件代付、修剪线头、商品盘点、商品个性化贴标等)和包裹增值服务(EPE泡沫填充物、纸护角卡条、拉伸膜、木架加固等)。": "增值服务，增值服务仅在商品采购、仓储打包、国际物流发货过程中可能产生收费。该服务包括商品增值服务(到付件代付、修剪线头、商品盘点、商品个性化贴标等)和包裹增值服务(EPE泡沫填充物、纸护角卡条、拉伸膜、木架加固等)。",
    "店铺服务，taoify.shop为您提供多样化的店铺装修、商品管理、订单管理等店铺运营管理收费服务，包括店铺搭建托管、产品托管、订单托管、店铺运营托管等。服务项详情和收费标准请查看:": "店铺服务，taoify.shop为您提供多样化的店铺装修、商品管理、订单管理等店铺运营管理收费服务，包括店铺搭建托管、产品托管、订单托管、店铺运营托管等。服务项详情和收费标准请查看:",
    "(三)商品存储与销毁": "(三)商品存储与销毁",
    "商品存储，每一件寄送至taoify.shop仓库的商品，都可以享受30天的免费存储服务(从商品签收时间开始计算)。并且，在30天免费存储服务到期后，您可以继续购买付费存储服务延长存储期限，通常，我们会在存储期即将到期前3天，以及到期当天向您发送邮件提醒，请您及时完成商品寄送，或购买付费存储服务延长商品存储期。": "商品存储，每一件寄送至taoify.shop仓库的商品，都可以享受30天的免费存储服务(从商品签收时间开始计算)。并且，在30天免费存储服务到期后，您可以继续购买付费存储服务延长存储期限，通常，我们会在存储期即将到期前3天，以及到期当天向您发送邮件提醒，请您及时完成商品寄送，或购买付费存储服务延长商品存储期。",
    "商品销毁，如果您在商品存储服务已过期30天后，商品仍在仓库未寄送，目没有购买任何付费存储服务或支付相关服务费用，我们将对过期商品讲行销股，已销毁的商品将无法继续存储或寄出。通常，我们会在商品即将销毁前3天，以及销毁当天向您发送邮件提醒。商品销毁后，您将没有权利向taoify.shop索要已销毁的商品，或申请相关赔偿": "商品销毁，如果您在商品存储服务已过期30天后，商品仍在仓库未寄送，目没有购买任何付费存储服务或支付相关服务费用，我们将对过期商品讲行销股，已销毁的商品将无法继续存储或寄出。通常，我们会在商品即将销毁前3天，以及销毁当天向您发送邮件提醒。商品销毁后，您将没有权利向taoify.shop索要已销毁的商品，或申请相关赔偿",
    "四、账户的注册，使用和注销": "四、账户的注册，使用和注销",
    "(一)注册": "(一)注册",
    "当您进入我们的网站账号注册页面(https://taoify.shop)填写必要信息并完成注册，通过taoify.shop用户验证后取得taoify.shop用户账户，您即可使用taoify.shop为您提供的用户服务，享受taoify.shop后台相应的操作权限。您有义务在注册时提供自己的真实材料，并保证如电子邮件地址等内容的有效性和安全性保证taoify.shop可通过上述联系方式与您取得联系。同时，您也有义务在相关资料实际变更时及时更新注册资料，并同时保证不以他人资料在taoify.shop进行注册或认证。基于风险控制的需要，taoify.shop平台可决定是否向您提供用户账户。": "当您进入我们的网站账号注册页面(https://taoify.shop)填写必要信息并完成注册，通过taoify.shop用户验证后取得taoify.shop用户账户，您即可使用taoify.shop为您提供的用户服务，享受taoify.shop后台相应的操作权限。您有义务在注册时提供自己的真实材料，并保证如电子邮件地址等内容的有效性和安全性保证taoify.shop可通过上述联系方式与您取得联系。同时，您也有义务在相关资料实际变更时及时更新注册资料，并同时保证不以他人资料在taoify.shop进行注册或认证。基于风险控制的需要，taoify.shop平台可决定是否向您提供用户账户。",
    "(二)使用": "(二)使用",
    "身份要素是我们识别您身份的依据，请您务必妥善保管。使用身份要素进行的操作，发出的指令视为您本人做出。因您的原因造成的账户，密码等信息被冒用或盗用，由此引起的风险和损失需要由您自行承担。": "身份要素是我们识别您身份的依据，请您务必妥善保管。使用身份要素进行的操作，发出的指令视为您本人做出。因您的原因造成的账户，密码等信息被冒用或盗用，由此引起的风险和损失需要由您自行承担。",
    "您的taoify.shop用户账户在新设备首次登录时，我们可能通过邮件认证的方式识别您的身份。": "您的taoify.shop用户账户在新设备首次登录时，我们可能通过邮件认证的方式识别您的身份。",
    "用户账户仅限您本人使用，请勿转让，借用，赠与，由此引起的风险和损失需要由您自行承担。": "用户账户仅限您本人使用，请勿转让，借用，赠与，由此引起的风险和损失需要由您自行承担。",
    "为了您的账号安全，在使用taoify.shop账号服务时，请您事先自行核实操作规则或谨慎决定是否授权给代运营团队操作": "为了您的账号安全，在使用taoify.shop账号服务时，请您事先自行核实操作规则或谨慎决定是否授权给代运营团队操作",
    "您在taoify.shop平台上发生的所有交易和扣款(包括BuckyShop平台)，都表示您授权我们会根据各个服务项的收费标准和事先签订的双方合作条款等操作规则由taoify.shop系统自动扣缴或由我们财务手动扣缴": "您在taoify.shop平台上发生的所有交易和扣款(包括BuckyShop平台)，都表示您授权我们会根据各个服务项的收费标准和事先签订的双方合作条款等操作规则由taoify.shop系统自动扣缴或由我们财务手动扣缴",
    "五、taoify.shop自动扣款授权": "五、taoify.shop自动扣款授权",
    "本协议所述授权内容由您向taoify.shop平台承诺确认，且有授权之法律效力，请您务必审慎阅读，充分理解此条款内容，特别是免除或者限制责任的条款，您应重点阅读。除非您已阅读并接受此条款，否则您无权使用taoify.shop平台的自动续费、自动扣款等服务。您同意此条款即视为您已向taoify.shop平台提供自动扣款授权，并自愿承担由此导致的一切法律后果。如您有任何舒问，应向taoify.shop客服(电话+8615038350530)咨询": "本协议所述授权内容由您向taoify.shop平台承诺确认，且有授权之法律效力，请您务必审慎阅读，充分理解此条款内容，特别是免除或者限制责任的条款，您应重点阅读。除非您已阅读并接受此条款，否则您无权使用taoify.shop平台的自动续费、自动扣款等服务。您同意此条款即视为您已向taoify.shop平台提供自动扣款授权，并自愿承担由此导致的一切法律后果。如您有任何舒问，应向taoify.shop客服(电话+8615038350530)咨询",
    "taoify.shop平台在首次扣款时需要获取您的授权验证，您勾选授权后即视为您授权taoify.shop可依据您所选服务项目的交易指令从您的taoify.shop账户余额中扣划对应的款项月taoify.shop后续在扣款时，将无需再验证您的授权信息。": "taoify.shop平台在首次扣款时需要获取您的授权验证，您勾选授权后即视为您授权taoify.shop可依据您所选服务项目的交易指令从您的taoify.shop账户余额中扣划对应的款项月taoify.shop后续在扣款时，将无需再验证您的授权信息。",
    "您在扣款账户内必须预留足够被扣划的余额，否则因账户余额不足导致无法及时扣款或扣款错误，失败，责任由您自行承担。因不可归责事由，导致的不能及时划账付款、划账错误等责任与taoify.shop平台无关": "您在扣款账户内必须预留足够被扣划的余额，否则因账户余额不足导致无法及时扣款或扣款错误，失败，责任由您自行承担。因不可归责事由，导致的不能及时划账付款、划账错误等责任与taoify.shop平台无关",
    "您确认，因taoify.shop平台的原因导致您遭受经济损失的，由您与taoify.shop平台协商解决": "您确认，因taoify.shop平台的原因导致您遭受经济损失的，由您与taoify.shop平台协商解决",
    "六、使用taoify.shop用户服务的注意事项": "六、使用taoify.shop用户服务的注意事项",
    "(一)极简账号": "(一)极简账号",
    "(二)为有效保障您使用我们服务时的合法权益，我们提醒您注意身份验证事项：": "(二)为有效保障您使用我们服务时的合法权益，我们提醒您注意身份验证事项：",
    "八、用户权益保障及信息保护": "八、用户权益保障及信息保护",
    "九、不可抗力、免责及责任限制": "九、不可抗力、免责及责任限制",
    "十、配套规则和协议": "十、配套规则和协议",
    "十一、知识产权的保护": "十一、知识产权的保护",
    "十二、法律适用": "十二、法律适用",
    "填写您的店铺名称": "填写您的店铺名称",
    "请输入您的店铺域名": "请输入您的店铺域名",
    "请输入taoify代购站域名": "请输入taoify代购站域名",
    "填写您的电子邮箱": "填写您的电子邮箱",
    "填写您的店铺密码": "填写您的店铺密码",
    "请输入邮箱验证码": "请输入邮箱验证码",
    "搜索": "搜索",
    "填写您的电话号码": "填写您的电话号码",
    "店铺类型": "店铺类型",
    "选择最适合你的类型和套餐，开启建店第一步！": "选择最适合你的类型和套餐，开启建店第一步！",
    "省心开店": "省心开店",
    "上百种精选电商主题模板一键应用，卡片式操作，无需设计能力，快速拥有一个能提现您品牌调性、安全、稳定的在线商店": "上百种精选电商主题模板一键应用，卡片式操作，无需设计能力，快速拥有一个能提现您品牌调性、安全、稳定的在线商店",
    "强大的应用生态": "强大的应用生态",
    "丰富多元的应用市场，成熟完善的服务生态，提供您所需的选品供应、引流获客、物流履约、客户管理、功能拓展等多种工具和服务": "丰富多元的应用市场，成熟完善的服务生态，提供您所需的选品供应、引流获客、物流履约、客户管理、功能拓展等多种工具和服务",
    "免费使用": "免费使用",
    "一站式工作台，多语言切换的操作模式，轻松管理商店的一切事务": "一站式工作台，多语言切换的操作模式，轻松管理商店的一切事务",
    "离开": "离开",
    "下一步": "下一步",
    "店铺商品": "店铺商品",
    "超多热门商品包，无需上传，一键开启你的店铺销售": "超多热门商品包，无需上传，一键开启你的店铺销售",
    "请选择您想要进行售卖的商品种类，例如：日杂百货、原创服饰、鞋包配件、工厂直营等~": "请选择您想要进行售卖的商品种类，例如：日杂百货、原创服饰、鞋包配件、工厂直营等~",
    "选择商品": "选择商品",
    "查看商品详情": "查看商品详情",
    "后退": "后退",
    "店铺模板": "店铺模板",
    "请选择您想要的模板~": "请选择您想要的模板~",
    "选择模板": "选择模板",
    "店铺信息": "店铺信息",
    "编辑一个简洁好记的店铺信息，进行注册，方便用户进行检索商店": "编辑一个简洁好记的店铺信息，进行注册，方便用户进行检索商店",
    "店铺名称": "店铺名称",
    "必填项": "必填项",
    "创建一个简单好记的店铺名称吧~他将会置于店铺顶部": "创建一个简单好记的店铺名称吧~他将会置于店铺顶部",
    "店铺域名": "店铺域名",
    "设置一个简洁的前缀，方便用户检索商店~他将会置于店铺顶部": "设置一个简洁的前缀，方便用户检索商店~他将会置于店铺顶部",
    "关联的taoify代购站域名": "关联的taoify代购站域名",
    "若您已拥有taoify代购站，可实现代购站和店铺的数据互通": "若您已拥有taoify代购站，可实现代购站和店铺的数据互通",
    "个人信息": "个人信息",
    "店铺密码": "店铺密码",
    "邮箱验证码": "邮箱验证码",
    "验证码已发送": "验证码已发送",
    "电话号码": "电话号码",
    "区号": "区号",
    "即表示您已同意": "即表示您已同意",
    "条款与注册协议": "条款与注册协议",
    "创建": "创建",
    "商品详情": "商品详情",
    "共有": "共有",
    "个商品": "个商品",
    "商城站": "商城站",
    "不单是建立了一个网站，而是建立了一个完整的、自动履单免维护，并且可无限扩展升级的事业": "不单是建立了一个网站，而是建立了一个完整的、自动履单免维护，并且可无限扩展升级的事业",
    "代购站": "代购站",
    "创建一个电子商务网站，帮您在线采购中国商品，系统化订单管理，促进销售并管理您的日常工作。": "创建一个电子商务网站，帮您在线采购中国商品，系统化订单管理，促进销售并管理您的日常工作。",
    "请填写有效的域名": "请填写有效的域名",
    "请填写正确的邮箱": "请填写正确的邮箱",
    "点击发送验证码": "点击发送验证码",
    "请填写店铺名称": "请填写店铺名称",
    "请填写有效的店铺域名": "请填写有效的店铺域名",
    "请填写有效的电子邮箱": "请填写有效的电子邮箱",
    "请填写店铺密码": "请填写店铺密码",
    "请填写邮箱验证码": "请填写邮箱验证码",
    "请填写手机号码": "请填写手机号码",
    "请选择区号": "请选择区号",
    "请选择店铺商品": "请选择店铺商品",
    "请选择店铺模板": "请选择店铺模板",
    "立即建立您的taoify商店": "立即建立您的taoify商店",
    "免费使用taoify,并开始探索、启动、运营和发展业务所需要的工具和服务": "免费使用taoify,并开始探索、启动、运营和发展业务所需要的工具和服务",
    "开始全球销售": "开始全球销售",
    "用taoify工具创建您的商店，打造和发展您的品牌": "用taoify工具创建您的商店，打造和发展您的品牌",
    "免费开通您的taoify在线商店，无需任何费用": "免费开通您的taoify在线商店，无需任何费用",
    "创建您的商店": "创建您的商店",
    "只需简单几步，就能帮助您开通taoify跨境在线商店": "只需简单几步，就能帮助您开通taoify跨境在线商店",
    "选择店铺类型": "选择店铺类型",
    "选择售卖商品": "选择售卖商品",
    "选择店铺模板": "选择店铺模板",
    "精选电商主题模板一键应用，卡片式操作，无需设计能力，快速拥有一个能提现您品牌调性、安全、稳定的在线商店": "精选电商主题模板一键应用，卡片式操作，无需设计能力，快速拥有一个能提现您品牌调性、安全、稳定的在线商店",
    "编辑店铺信息": "编辑店铺信息",
    "自由选择中国淘宝，1688产品库热门商品": "自由选择中国淘宝，1688产品库热门商品",
    "全球销售您的产品": "全球销售您的产品",
    "自由上传自营商品到taoify商店": "自由上传自营商品到taoify商店",
    "在线销售": "在线销售",
    "使用taoify内置SEO、自动化营销工具、谷歌、Facebook等主要营销渠道集成的应用程序": "使用taoify内置SEO、自动化营销工具、谷歌、Facebook等主要营销渠道集成的应用程序",
    "订单和物流管理": "订单和物流管理",
    "taoify管理和配送来自您商店的订单，向消费者发送订单物流信息，让消费者获得更好的购物体验": "taoify管理和配送来自您商店的订单，向消费者发送订单物流信息，让消费者获得更好的购物体验",
    "数据管理": "数据管理",
    "所有来自您商店的订单、财务、会员数据都可以实时查看，帮助您更好的开展业务": "所有来自您商店的订单、财务、会员数据都可以实时查看，帮助您更好的开展业务",
    "在线客服": "在线客服",
    "直接在商店里与消费者沟通，提升千倍销量，接触并留住消费者": "直接在商店里与消费者沟通，提升千倍销量，接触并留住消费者",
    "业务资金更安全": "业务资金更安全",
    "可直接绑定收款账户，随时查看收款资金": "可直接绑定收款账户，随时查看收款资金",
    "查看模板详情": "查看模板详情",
    "更多模板敬请期待": "更多模板敬请期待",
    "取消选择": "取消选择",
    "商店已经准备就绪": "商店已经准备就绪",
    "电子邮箱": "电子邮箱",
    "店铺地址": "店铺地址",
    "请确认基本的商店信息，之后您可以在管理中更改订阅": "请确认基本的商店信息，之后您可以在管理中更改订阅",
    "多达": "多达",
    "一键上传到你的商店，销售什么商品由你来决定": "一键上传到你的商店，销售什么商品由你来决定",
    "数10种类付款方式，您的顾客在选择好商品之后，可以选择当地的安全支付方式为您快捷收款": "数10种类付款方式，您的顾客在选择好商品之后，可以选择当地的安全支付方式为您快捷收款",
    "简化订单，跟踪付款和查看实时业务，因此您可以更专注于建立您的业务": "简化订单，跟踪付款和查看实时业务，因此您可以更专注于建立您的业务",
    "共": "共",
    "种商品": "种商品",
    "查看您的商店业务概述": "查看您的商店业务概述",
    "管理后台": "管理后台",
    "点击进入我的店铺": "点击进入我的店铺",
    "进入我的店铺": "进入我的店铺",
    "去修改": "去修改",
    "开通": "开通",
    "请输入您的店铺地址": "请输入您的店铺地址",
    "taoify 一站式": "taoify 一站式",
    "请阅读并同意条款": "请阅读并同意条款",
    "请输入字母或数字做为您的店铺域名": "请输入字母或数字做为您的店铺域名",
    "模版市场": "模版市场",
    "适用于任何项目的专业主题网站模板": "适用于任何项目的专业主题网站模板",
    "根据自己的需求选择合适的模板，并在其基础上进行自定义和修改，以满足自己的品牌形象和功能要求。": "根据自己的需求选择合适的模板，并在其基础上进行自定义和修改，以满足自己的品牌形象和功能要求。",
    "搜索您想要的模板": "搜索您想要的模板",
    "最新的主题和模板": "最新的主题和模板 ",
    "查看更多": "查看更多",
    "查看详情": "查看详情",
    "本周精选创作者": "本周精选创作者",
    "数百万创意资源、无限下载": "数百万创意资源、无限下载",
    "立即订阅、可随时取消": "立即订阅、可随时取消",
    "轻松的设计有您自己在线制作": "轻松的设计有您自己在线制作",
    "适合任何技能水平的智能模板": "适合任何技能水平的智能模板",
    "模版价格": "模版价格",
    "免费": "免费",
    "立即免费使用": "立即免费使用",
    "未来更新": "未来更新",
    "提供6个月的支持": "提供6个月的支持",
    "由taoify检查质量": "由taoify检查质量",
    "关于作者": "关于作者",
    "高分辨率": "高分辨率",
    "是的": "是的",
    "支付方式": "支付方式",
    "可兼容浏览器": "可兼容浏览器",
    "布局": "布局",
    "标签": "标签",
    "不支持": "不支持",
    "余额提现": "余额提现",
    "提现记录": "提现记录",
    "购买记录": "购买记录",
    "我的收益": "我的收益",
    "应用到当前店铺": "应用到当前店铺",
    "删除当前模版": "删除当前模版",
    "查看模版详情": "查看模版详情",
    "无结果": "无结果",
    "已购买模板": "已购买模板",
    "自定义模板": "自定义模板",
    "审核通过": "审核通过",
    "审核失败": "审核失败",
    "已发布": "已发布",
    "未发布": "未发布",
    "发表时间":"发表时间",
    "更新时间":"更新时间",
    "模版ID":"模版ID",
    "主题模板特点":"主题模板特点",
    "已购买":"已购买",
    "实时预览":"实时预览",
    "待发布":"待发布",
    "待审核":"待审核",
    "审核通过":"审核通过",
    "审核未通过":"审核未通过",
    "类型":"类型",
    "时间":"时间",
    "详情":"详情",
    "金额":"金额",
    "个人信息":"个人信息",
    "余额提现":"余额提现",
    "提现记录":"提现记录",
    "购买记录":"购买记录",
    "消费记录":"消费记录",
    "我的收益":"我的收益",
    "订单编号":"订单编号",
    "收益明细":"收益明细",
    "搜索您想要的模版":"搜索您想要的模版",
    "全部自定义模版":"全部自定义模版",
    "小店管理":"小店管理",
    "模版中心":"模版中心",
    "退出登录":"退出登录",
    "发布模版到市场":"发布模版到市场",
    "还未购买模版":"还未购买模版",
    "立即免费开通":"立即免费开通",
    "已购买模版":"已购买模版",
    "当前版本号":"当前版本号",
    "有新版本可用":"有新版本可用",
    "":"",
    "":"",
    "":"",
    "":"",
    "":"",
    "":"",
    "":"",
    "":"",
    "":"",
    "":"",
    "":"",
    "":"",
}