import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    baseUrl:'https://api.taoify.shop',
 //  baseUrl:'http://192.168.31.139:85',
  // baseUrl:'http://192.168.0.194:85',
    langdef:localStorage.getItem('langdef')==''||localStorage.getItem('langdef')==undefined? 'zh-CN':localStorage.getItem('langdef'),
    USERINFO:JSON.parse(localStorage.getItem("DROPSHOPUSERINFO"))?JSON.parse(localStorage.getItem("DROPSHOPUSERINFO")):null,
    locationOrigin:location.origin,
    shop_web_domain:localStorage.getItem("shop_web_domain")?localStorage.getItem("shop_web_domain"):'',//header传值使用
    defaultShopid:localStorage.getItem("defaultShopid")?localStorage.getItem("defaultShopid"):'',//当前shopid header传值使用
  },
  mutations: {
    setLangDef(state,langdef){
      state.langdef = langdef
      localStorage.setItem('langdef',langdef)
    },
    setUSERINFO(state,data){
      localStorage.setItem('DROPSHOPUSERINFO',JSON.stringify(data))
      state.USERINFO=JSON.parse(localStorage.getItem("DROPSHOPUSERINFO"))
    },
  },
})
