import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from '@/lang/zh.js'
import en from '@/lang/en.js'
import store from '@/store/index'
Vue.use(VueI18n)
function getI18n() {
  // let obj = {}
  // for(let item in zh){
  //   if(item in obj == false){
  //     obj[item]
  //   }
  // }
  const langSign = store.state.langdef;
  const messages = {
    'en': en,
    'zh-CN': zh
  }
  // 翻译
  const i18n = new VueI18n({
    locale: langSign,
    messages: messages,
    silentTranslationWarn: true // 注释警告
  })
  return i18n
}
export default {
  getI18n
}