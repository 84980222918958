import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./lang";
import vuetify from "@/plugins/vuetify";
import api from './api/request'
import store from './store';
import "./plugins";
import "@/assets/scss/_global.scss";
import Toast from './components/Toast' //弹窗组件
import createToast from './plugins/create' //弹窗组件
import * as Filters from './plugins/filter' //过滤器
Vue.config.productionTip = false;


// plugin svgrender 
// import Vue plugin
import VueSvgInlinePlugin from "vue-svg-inline-plugin";
// use Vue plugin without options
// use Vue plugin with options
VueSvgInlinePlugin.install(Vue, {
  attributes: {
    data: ["src"],
    remove: ["alt"]
  }
});
//封装axios
Vue.prototype.$api = api;
//公共方法类

Vue.prototype.$Toast = ((obj) => {
  return createToast(Toast, obj).show();
})

//注册全局过滤器
Object.keys(Filters).forEach(key=>{
  Vue.filter(key,Filters[key])
})


new Vue({
  router,
  vuetify,
  store,
  i18n: i18n.getI18n(),
  render: h => h(App)
}).$mount("#app");
