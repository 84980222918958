<template>
  <v-fade-transition mode="out-in">
    <router-view />
    <!-- <v-dialog v-model="LoginInvalidObj"></v-dialog> -->
  </v-fade-transition>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      configlist: {},
    };
  },
  mounted() {
  },
  methods: {
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]); return null;
    },
  },
};
</script>

<style lang="scss"></style>
