import Ajax from './ajax';
const Request = {
  public:{
    getconfig(params) {
      return Ajax.post('/Admin/common/get_admin_config_info', params)
    },
    uploadimgs(params) {
      return Ajax.post('/Admin/common/upload_img', params);
    }
  },
  shop: {
    // 获取商品包列表
    get_goods_package_list(params) {
      return Ajax.post('/Admin/shop/get_goods_package_list', params);
    },
    // 获取商品包商品列表
    get_goods_package_info_list(params) {
      return Ajax.post('/Admin/shop/get_goods_package_info_list', params);
    },
    // 获取区域码列表
    get_country_list(params) {
      return Ajax.post('/Admin/shop/get_country_list', params);
    },
    // 获取验证码
    send_code(params) {
      return Ajax.post('/Admin/common/send_code', params);
    },
    //开通店铺 
    open_shop(params) {
      return Ajax.post('/Admin/shop/open_shop', params);
    },
    // 检测店铺余名是否可用
    check_web_domain(params) {
      return Ajax.post('/Admin/shop/check_web_domain', params);
    },
    // 检测店铺邮箱是否可用
    check_account(params) {
      return Ajax.post('/Admin/shop/check_account', params);
    },
    // 获取模板列表
    get_themes_list(params) {
      return Ajax.post('/Admin/shop/get_themes_list', params);
    },
    //添加店铺
    add(params) {
      return Ajax.post('/Admin/Admin/add_shop', params)
    },

  },
  help: {
    //获取帮助中心文章列表
    get_help_list(params) {
      return Ajax.post('/Admin/Helpcenter/get_help_center_list', params)
    },
    //获取帮助中心文章详情
    get_help_detail(params) {
      return Ajax.post('/Admin/Helpcenter/get_help_center_detail', params)
    }
  },

  template:{
    list(params){
      return Ajax.post('/Admin/Themes/get_themes_list',params)
    },
    detail(params){
      return Ajax.post('/Admin/Themes/get_themes_detail',params)
    },
    create(params){
      return Ajax.post('/Admin/Themesorder/create_themes_order',params)
    },
    payed(params){
      return Ajax.post('/Admin/Themesorder/pay_themes_order',params)
    },
    paytype(params){
      return Ajax.post('/Admin/Themesorder/get_pay_type',params)
    },
    order_list(params){
      return Ajax.post('/Admin/Themesorder/get_themes_order_list',params)
    },
    use_themes(params){
      return Ajax.post('/Admin/Themesorder/use_themes',params)
    },
    use_custom_themes(params){
      return Ajax.post('/Admin/Themescustom/use_upload_custom_themes ',params)
    },
    //更新已购买使用模版信息
    up_use_themes(params){
      return Ajax.post('/Admin/Themesorder/up_use_themes ',params)
    },
   //消费记录
    consume_list(params){
      return Ajax.post('/Admin/Themesorder/get_themes_consume_list',params)
    },
    //结算记录
    settlement_list(params){
      return Ajax.post('/Admin/Themesorder/get_themes_settlement_list',params)
    },
    //自定义模板
    themes_list(params){
      return Ajax.post('/Admin/Themescustom/get_custom_themes_list',params)
    },
    //自定义模板-详情
    themes_detail(params){
      return Ajax.post('/Admin/Themescustom/get_custom_themes_detail',params)
    },
    //自定义模板-发布
    themes_release(params){
      return Ajax.post('/Admin/Themescustom/release_custom_themes',params)
    },
    //自定义模板-删除
    themes_del(params){
      return Ajax.post('/Admin/Themescustom/del_custom_themes',params)
    },
    //获取获取创作者列表
    author_list(params){
      return Ajax.post('/Admin/Themes/get_themes_author_list',params)
    },
    //获取标签列表
    tag_list(params){
      return Ajax.post('/Admin/Themes/get_themes_tag_list',params)
    },
    
  }
};
export default Request;
