// 引入MD5
import axios from 'axios'
import store from '@/store/index'
// 创建axios
axios.defaults.retry = 4
axios.defaults.retryDelay = 1000

let website_baseURL = store.state.baseUrl
let USERINFO_=store.state.USERINFO;
let defaultShopid_ = store.state.defaultShopid;
let shopwebdomain_ = store.state.shop_web_domain;
let header={};
//USERINFO_.token
if(USERINFO_){
  header['token']=USERINFO_.token
}
if (shopwebdomain_ != '') {
  header['shopwebdomain'] = shopwebdomain_;
}
if (defaultShopid_ != '') {
  header['shopid'] = defaultShopid_
}
const service = axios.create({
  timeout: 50000, // request timeout
  baseURL: website_baseURL,
  headers:header
})
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  response => {
    
    return response.data
  },
  error => {
    // 超时处理
    var config = error.config
    // If config does not exist or the retry option is not set, reject
    if (!config || !config.retry) return Promise.reject(error)
    // Set the variable for keeping track of the retry count
    config.__retryCount = config.__retryCount || 0

    // Check if we've maxed out the total number of retries
    if (config.__retryCount >= config.retry) {
      // Reject with the error
      return Promise.reject(error)
    }
    // Increase the retry count
    config.__retryCount += 1
    // Create new promise to handle exponential backoff
    var backoff = new Promise(function (resolve) {
      setTimeout(function () {
        resolve()
      }, config.retryDelay || 1)
    })
    // Return the promise in which recalls axios to retry the request
    return backoff.then(function () {
      return axios(config)
    })
    // return Promise.reject(error)
  }
)
export default service
